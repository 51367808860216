import {
  Box,
  Skeleton,
  Stack,
  Tabs,
  Tab,
  Typography,
  Divider,
} from "@mui/material";

interface HeaderTabsProps {
  loading: boolean;
  tabIndex: number;
  onChange: (newValue: number) => void;
  tabs: HeaderTabConfig[];
}

export interface HeaderTabConfig {
  label: string;
}

export const HeaderTabs = ({
  loading,
  onChange,
  tabIndex,
  tabs,
}: HeaderTabsProps): JSX.Element | null => {
  if (!tabs.length) {
    return null;
  }
  const handleChange = (event: React.SyntheticEvent, newValue?: number) => {
    if (newValue !== undefined) {
      onChange(newValue);
    }
  };
  return (
    <Box mb={2}>
      {loading ? (
        <Stack direction={"column"} spacing={2} mb={2}>
          <Stack direction={"row"} spacing={2} mb={2} sx={{}}>
            {tabs.map((tab, index: number) => (
              <Skeleton key={index} animation="wave" variant={"rounded"}>
                <Typography>Tab Name</Typography>
              </Skeleton>
            ))}
          </Stack>
          <Divider />
        </Stack>
      ) : (
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="Tabs example"
          variant={"standard"}
          sx={{
            ".MuiTabs-flexContainer": {
              gap: "12px",
            },
            ".MuiTabs-indicator": {
              backgroundColor: "#004EEB",
            },
            background: "transparent",
            borderBottom: "1px solid #E9EAEB",
            minHeight: "auto",
          }}
        >
          {tabs.map((tab, index: number) => (
            <Tab
              key={index}
              disableRipple
              label={tab.label}
              value={index}
              sx={{
                "&.Mui-selected": {
                  color: "#004EEB",
                  fontWeight: "600",
                },
                color: "#717680",
                fontFamily: '"Inter", sans-serif',
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                minHeight: "auto",
                minWidth: "auto",
                padding: "0px 4px 12px 4px",
                textTransform: "capitalize !important",
              }}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};
