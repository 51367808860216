import { isImageFile, uploadImage } from "@hooks/utils/useUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert, FormLabel, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useFormik } from "formik";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { PermitFeeLineItem } from "permit-one-common/src/interfaces/permitFee";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as yup from "yup";

interface FeeFormProps {
  project: ProjectLineItem;
  permit: PermitLineItem;
  fee: PermitFeeLineItem;
  handleSubmit: (permit: PermitFeeLineItem) => void;
  handleClose: () => void;
}

export const FeeForm = ({
  fee,
  handleSubmit,
  permit,
  project,
}: FeeFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState<string>();

  const validationSchema = yup.object({
    feeName: yup.string().required("Fee name is required"),
    url: yup.string().required("Upload required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      feeName: fee.feeName,
      url: fee.url,
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...fee,
        feeName: values.feeName,
        url: values.url,
      });
    },
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const files = e.currentTarget.files;

      const fileToUpload = files[0];

      if (fileToUpload) {
        setIsUploading(true);
        const fileKey = `fees/${project.id}/${permit.id}/${fileToUpload.name}`;
        if (isImageFile(fileToUpload.name)) {
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);

              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            }
          );
        }

        formik.setFieldValue("url", fileKey);
        setFileName(fileToUpload.name);
      }
      setIsUploading(false);
    }
  };

  const formDisabled = formik.isSubmitting || isUploading;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} sm={12} sx={{ padding: "0 0 15px" }}>
          <TextField
            id="feeName"
            name="feeName"
            label="Fee Name"
            fullWidth
            margin="dense"
            disabled={formDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.feeName}
            error={formik.touched.feeName && Boolean(formik.errors.feeName)}
            helperText={formik.touched.feeName ? formik.errors.feeName : ""}
          />
        </Grid>
        <Grid item sm={12} md={12} sx={{ padding: "0 0 30px" }}>
          <Stack>
            <FormLabel
              component="legend"
              sx={{
                color: "black",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "0 0 10px",
              }}
            >
              Upload Fee
            </FormLabel>
            <Button
              variant="contained"
              disabled={formDisabled}
              onClick={onFileInput}
              className="outlined-btn"
              sx={{
                gap: "10px",
              }}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                multiple
                type="file"
                onChange={onUpload}
              />
              <CloudUploadIcon /> Upload
            </Button>
            {isUploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : null}
            {fileName ? (
              <Alert
                color="success"
                sx={{ background: "#ebebeb", marginTop: "10px" }}
              >
                {fileName}
              </Alert>
            ) : null}
          </Stack>
        </Grid>

        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton loading={false} type="submit" disabled={formDisabled}>
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
