import { Box, InputAdornment, Skeleton, TextField } from "@mui/material";
import { SearchButtonIcon } from "assets/constants";
import { useState } from "react";

interface SearchBoxProps {
  searchTerm: string;
  loading: boolean;
  handleSearchChange: (searchTerm: string) => void;
}

const SearchBox = ({
  handleSearchChange,
  loading,
  searchTerm,
}: SearchBoxProps) => {
  const [searchWidth, setSearchWidth] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e.target.value);
  };

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <Box
          sx={{
            width: {
              sm: "48px",
              xs: "100%",
            },
          }}
          className="search-container"
        >
          <TextField
            className="search-box"
            variant="standard"
            placeholder="Search"
            disabled={loading}
            fullWidth
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  className="icon"
                  position="start"
                  sx={{
                    margin: "0 0 0 0",
                    p: 0,
                  }}
                >
                  <SearchButtonIcon />
                </InputAdornment>
              ),
              style: {
                backgroundColor: "white",
                borderColor: "#D5D7DA",
                fontSize: "14px",
                lineHeight: "20px",
                opacity: "100%",
                outline: "none",
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                alignItems: "center",
                borderRadius: "0px !important",
                color: "#717680",
                fontFamily: '"Inter", sans-serif',
                fontSize: "14px",
                height: "auto",
                lineHeight: "20px",
                ml: 1,

                padding: "0px",
              },

              bgcolor: "white",
              border: "1px solid #D5D7DA",
              borderRadius: "8px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              padding: "8px 14px 8px 12px",
            }}
          />
        </Box>
      </Skeleton>
    );
  }
  return (
    <Box
      sx={{
        width: {
          sm: `${searchWidth || searchTerm ? "320px" : "48px"}`,
          xs: "100%",
        },
      }}
      className="search-container"
    >
      <TextField
        className="search-box"
        variant="standard"
        placeholder="Search"
        disabled={loading}
        onMouseEnter={!loading ? () => setSearchWidth(!searchWidth) : undefined}
        onMouseLeave={!loading ? () => setSearchWidth(!searchWidth) : undefined}
        value={searchTerm}
        onChange={onChange}
        fullWidth
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment
              className="icon"
              position="start"
              sx={{
                margin: "0 0 0 0",
                p: 0,
              }}
            >
              <SearchButtonIcon />
            </InputAdornment>
          ),
          style: {
            backgroundColor: "white",
            borderColor: "#D5D7DA",
            fontSize: "14px",
            lineHeight: "20px",
            opacity: "100%",
            outline: "none",
          },
        }}
        sx={{
          "& .MuiInputBase-input": {
            alignItems: "center",
            borderRadius: "0px !important",
            color: "#717680",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            height: "auto",
            lineHeight: "20px",
            ml: 1,

            padding: "0px",
          },

          bgcolor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "8px 14px 8px 12px",
        }}
      />
    </Box>
  );
};

export default SearchBox;
