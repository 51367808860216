import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ProfileSection from "@stories/molecules/ProfileSection";
import { drawerWidth } from "constant";
import { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import MiniDrawerStyled from "./MiniDrawerStyled";
import SearchSection from "../Header/SearchSection";
import LogoSection from "../LogoSection";
import MenuList from "../MenuList";

interface SidebarProps {
  isDrawOpen: boolean;
  selectedItem: string[];
  setIsDrawOpen: (open: boolean) => void;
  setSelectedItem: (item: string[]) => void;
}

const Sidebar = ({
  isDrawOpen,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: SidebarProps) => {
  const theme = useTheme();
  const { isAuthProfileLoading } = useProfileContext();
  const { isProjectLoading, selectedProject } = useProjectContext();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const drawerContent = (
    <>
      <SearchSection />
      <MenuList
        loading={isAuthProfileLoading || isProjectLoading}
        selectedProject={selectedProject}
        isDrawOpen={isDrawOpen}
        setIsDrawOpen={setIsDrawOpen}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </>
  );

  const drawerSX = {
    background: "transparent",
    marginTop: "17px",
    paddingLeft: isDrawOpen ? "16px" : 0,
    paddingRight: isDrawOpen ? "16px" : 0,
  };

  const drawer = matchDownMd ? (
    <Box sx={drawerSX}>{drawerContent}</Box>
  ) : (
    <PerfectScrollbar
      component="div"
      style={{
        height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 89px)",
        ...drawerSX,
      }}
    >
      {drawerContent}
      <ProfileSection />
    </PerfectScrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
      className="sidebar"
    >
      {matchDownMd || isDrawOpen ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={isDrawOpen}
          onClose={() => setIsDrawOpen(!isDrawOpen)}
          sx={{
            "& .MuiDrawer-paper": {
              background: "transparent",
              borderRight: "none",
              color: "white",
              mt: matchDownMd ? 0 : 11,
              width: drawerWidth,
              zIndex: 1099,
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={isDrawOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;
