import { getImageUrl, uploadImage } from "@hooks/utils/useUpload";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  Avatar,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useFormik } from "formik";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import phone from "phone";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as yup from "yup";

interface ProfileFormProps {
  profile?: ProfileLineItem;
  loading: boolean;
  handleSubmit: (profile: ProfileLineItem) => void;
}

export const ProfileForm = ({
  handleSubmit,
  loading,
  profile,
}: ProfileFormProps) => {
  const [logoErrorMessage, setLogoErrorMessage] = useState<string>();
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [tempLogoUrl, setTempLogoUrl] = useState(profile?.photoUrl);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    jobTitle: yup.string().required("Job title is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .test("Valid Phone", "Phone not valid for country", function (item) {
        const res = phone(item, {
          country: this.parent.country,
          validateMobilePrefix: false,
        });
        return res.isValid;
      }),
    photoUrl: yup.string().required("Logo is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile?.firstName || "",
      jobTitle: profile?.jobTitle || "",
      lastName: profile?.lastName || "",
      originalPhotoUrl: profile?.originalPhotoUrl || "",
      phoneNumber: profile?.phoneNumber || "",
      photoUrl: profile?.photoUrl || "",
    },
    onSubmit: async (values) => {
      if (profile) {
        await handleSubmit({
          ...profile,
          firstName: values.firstName,
          jobTitle: values.jobTitle,
          lastName: values.lastName,
          originalPhotoUrl: values.originalPhotoUrl,
          phoneNumber: values.phoneNumber,
          photoUrl: values.photoUrl,
        });
      }
    },
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const fileSize = e.currentTarget?.files[0].size / 1024 / 1024;

      if (fileSize > 2) {
        setLogoErrorMessage("File size should not exceed 2Mb");
        return;
      }
      setLogoErrorMessage(undefined);
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);

        const fileKey = `logos/${profile?.id}/${file.name}`;
        await uploadImage(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          () => {
            setUploadProgress(0);

            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          }
        );

        const presignedUrl = await getImageUrl(fileKey);
        formik.setFieldValue("photoUrl", presignedUrl);
        formik.setFieldValue("originalPhotoUrl", fileKey);
        const urlToImage = URL.createObjectURL(file);
        setTempLogoUrl(urlToImage);
        setIsUploading(false);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={1}
        sx={{
          alignItems: "left",
          display: "flex",
          width: "100%",
        }}
      >
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            margin="dense"
            autoComplete="given-name"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName ? formik.errors.firstName : ""}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="lastName"
            name="lastName"
            label="Last Name"
            fullWidth
            margin="dense"
            autoComplete="family-name"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName ? formik.errors.lastName : ""}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="jobTitle"
            name="jobTitle"
            label="Job Title"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jobTitle}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle ? formik.errors.jobTitle : ""}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || isUploading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={
              formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
            }
          />
        </Grid>
        <Grid
          item
          md={8}
          sx={{
            alignItems: "center",
            display: "flex !important",
            gap: "15px",
            justifyContent: "left !important",
            padding: "30px 10px 0",
          }}
        >
          {isUploading ? (
            <Grid item xs={12} md={12} sx={{ display: "flex", gap: "20px" }}>
              <CircularProgressWithLabel value={uploadProgress} />
            </Grid>
          ) : (
            <Grid item xs={12} md={8} sx={{ display: "flex", gap: "20px" }}>
              <Grid item sx={{}}>
                <Avatar
                  alt="User 3"
                  src={tempLogoUrl}
                  sx={{ backgroundColor: "#ffffff", height: 64, width: 64 }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Grid item xs={12}>
                  <input
                    ref={fileInput}
                    hidden
                    accept="image/*"
                    type="file"
                    style={{
                      cursor: "pointer",
                      height: "100px",
                      opacity: 0,
                      padding: 0.5,
                      position: "fixed",
                      width: "100px",
                      zIndex: 1,
                    }}
                    onChange={onUpload}
                  />
                  <FormControlLabel
                    sx={{
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: "10px",
                      margin: "auto",
                    }}
                    control={
                      <Tooltip
                        TransitionComponent={Zoom}
                        placement="top"
                        title="Upload"
                      >
                        <IconButton
                          color="error"
                          sx={{
                            "&:hover ": {
                              background: theme.palette.primary.dark,
                              color: theme.palette.primary.light,
                            },
                            background: theme.palette.primary.light,
                            borderColor: "black",
                            color: theme.palette.primary.dark,
                          }}
                          disabled={isUploading}
                          size="large"
                          onClick={onFileInput}
                        >
                          <UploadTwoToneIcon
                            sx={{
                              fontSize: "24px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    }
                    label={"Upload Profile Logo"}
                  />
                </Grid>
                {logoErrorMessage ? (
                  <Typography
                    variant="caption"
                    sx={{ color: "red", fontSize: "11px", marginTop: "-5px" }}
                  >
                    {logoErrorMessage}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "11px", marginTop: "-5px" }}
                  >
                    *Image size should not exceed 2Mb Max.
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            alignItems: "flex-start",
            display: "flex !important",
            justifyContent: "right !important;",
            padding: "40px 0 0 10px !important",
          }}
        >
          <StyledButton
            loading={loading}
            type="submit"
            disabled={loading || !formik.dirty || formik.isSubmitting}
          >
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
