import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProfile } from "@hooks/crud/useProfile";
import { Box } from "@mui/material";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { UserTable2 } from "@stories/molecules/UserTable/UserTable2";
import { Header, HeaderButtonType } from "@stories/organisms/Header/Header";
import UserModal from "@stories/organisms/UserModal/UserModal";
import { AddEmployeeIcon } from "assets/constants";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";

export const Users = () => {
  const [selectedProfile, setSelectedProfile] = useState<
    ProfileLineItem | undefined
  >(undefined);

  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const {
    deleteProfile,
    inviteProfile,
    isProfilesLoading,
    profiles,
    updateProfile,
  } = useProfile(userOrganisation?.id);

  const [open, setOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  if (!userProfile) {
    return <PageLoader />;
  }
  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(undefined);
  };
  const handleOpen = () => {
    setSelectedProfile(undefined);
    setOpen(true);
  };

  const handleInviteProfile = async (profile: ProfileLineItem) => {
    await inviteProfile(profile);
  };

  const handleEditUser = (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    setOpen(true);
  };

  const handleUpdateProfile = (profile: ProfileLineItem) => {
    updateProfile(profile);
  };

  const handleDeleteUser = (profile: ProfileLineItem) => {
    deleteProfile(profile);
  };

  const loading = isProfilesLoading || isAuthProfileLoading;

  const buttons = [
    {
      action: async () => {
        handleOpen();
      },
      icon: <AddEmployeeIcon variant="secondary" />,
      text: "Download Checklist",
      type: HeaderButtonType.PRIMARY,
      width: 180,
    },
  ];
  return (
    <>
      <Box>
        <Header
          loading={loading}
          subTitle={"Manage users and permission levels"}
          mainTitle={"Users"}
          handleSearchChange={handleSearchChange}
          searchTerm={searchTerm}
          buttons={buttons}
        />
        <UserTable2
          loading={loading}
          profile={userProfile}
          profiles={profiles}
          searchTerm={searchTerm}
          handleDeleteUser={handleDeleteUser}
          handleEditUser={handleEditUser}
        />

        {userProfile && userOrganisation && open ? (
          <UserModal
            open={open}
            loading={loading}
            organisation={userOrganisation}
            profiles={profiles}
            handleClose={handleClose}
            updateUser={handleUpdateProfile}
            inviteUser={handleInviteProfile}
            existingUser={selectedProfile}
          />
        ) : null}
      </Box>
    </>
  );
};
