import { Grid } from "@mui/material";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ArchiveProjectIcon, ViewProjectIcon } from "assets/constants";
import {
  ConditionDiscipline,
  conditionDisciplineToString,
} from "permit-one-common/src/entities/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { generateDocumentName } from "permit-one-common/src/utils/string";
import { useCallback, useState } from "react";

import { Table } from "../Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  DropDownField,
  InputTextField,
} from "../Table/TableCells";

interface ReadWriteDocumentTableProps {
  loading: boolean;
  searchTerm: string;
  documentCount: number;
  project: ProjectLineItem;
  canEdit: boolean;
  profileId?: string;
  disabled: boolean;
  conditionDocuments: ConditionDocumentLineItem[];
  removeFile: (documents: ConditionDocumentLineItem) => void;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
}

export const ReadWriteDocumentTable = ({
  canEdit,
  conditionDocuments,
  disabled,
  documentCount,
  loading,
  profileId,
  project,
  removeFile,
  searchTerm,
  updateDocuments,
}: ReadWriteDocumentTableProps) => {
  const [documentKey, setDocumentKey] = useState<string>();

  const handleView = useCallback(
    async (conditinDocument: ConditionDocumentLineItem) => {
      const uri = conditinDocument?.url;
      if (!uri) return;
      setDocumentKey(uri);
    },
    []
  );

  const colDefs = [
    {
      field: "documentTitle",
      flex: 3,
      headerName: "Local Name",
      onCellClicked: (params) => {
        if (params.data.url) return handleView(params.data);
      },
      valueFormatter: (params) => {
        return (
          params.data?.file?.name ||
          generateDocumentName(
            project?.projectName || "",
            params.data.documentDiscipline,
            params.data.documentCategory,
            documentCount
          )
        );
      },
    },
    {
      cellRenderer: InputTextField,
      cellRendererParams: {
        paramKey: "documentTitle",
      },
      field: "documentTitle",
      flex: 3,
      headerName: "Title",
      onCellClicked: (params) => {
        if (params.data.url) return handleView(params.data);
      },
    },
    {
      cellRenderer: DropDownField,
      cellRendererParams: {
        label: "Discipline",
        options: Object.values(ConditionDiscipline).map((c) => {
          return { id: c, name: conditionDisciplineToString(c) };
        }),
        paramKey: "documentDiscipline",
        placeholder: "Discipline",
      },
      field: "documentDiscipline",
      flex: 3,
      headerName: "Discipline",
      onCellClicked: (params) => {
        if (params.data.url) return handleView(params.data);
      },
      valueFormatter: (params) => {
        return conditionDisciplineToString(params.data.documentDiscipline);
      },
    },
    {
      field: "created",
      flex: 2,
      headerName: "Created",
      onCellClicked: (params) => {
        if (params.data.url) return handleView(params.data);
      },
      valueFormatter: (params) => {
        return params.data.created.toLocaleDateString();
      },
    },
    {
      field: "created",
      flex: 2,
      headerName: "Created",
      onCellClicked: (params) => {
        if (params.data.url) return handleView(params.data);
      },
      valueFormatter: (params) => {
        return params.data.created.toLocaleDateString();
      },
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  const cellActions: CellAction[] = [
    {
      action: handleView,
      icon: <ViewProjectIcon />,
      title: "View Document",
    },
  ];

  if (canEdit) {
    cellActions.push({
      action: async (params: ConditionDocumentLineItem) => {
        removeFile(params);
      },
      icon: <ArchiveProjectIcon />,
      title: "Remove Document",
      validateEnabled: (data: ConditionDocumentLineItem) => {
        return profileId === data.createdBy;
      },
    });
  }
  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={conditionDocuments}
        searchValue={searchTerm}
        context={{ cellActions, disabled, updateDocuments }}
        searchKeys={["documentTitle", "createdByName"]}
      />
      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </Grid>
  );
};
