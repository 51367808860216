import { FormControl, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import {
  ConstructionType,
  constructionTypeToString,
} from "permit-one-common/src/entities/permit";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import * as yup from "yup";

interface PermitBuildingWorksFormProps {
  permit: PermitLineItem;
  handleBack: () => void;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitBuildingWorksForm = ({
  handleBack,
  handleSubmit,
  permit,
}: PermitBuildingWorksFormProps) => {
  const validationSchema = yup.object({
    bcaVersion: yup.string().required("BCA Version is required"),
    buildingClassifications: yup
      .string()
      .required("Building classifications is required"),
    riseInStoreys: yup.string().required("Rise in storeys is required"),
    stageDetailsOfWork: yup
      .string()
      .required("Stage details of work permitted is required"),
    storeysContained: yup.string().required("Storeys contained is required"),
    typeOfConstruction: yup
      .array()
      .required("Type of construction is required"),
  });
  const formik = useFormik({
    initialValues: {
      bcaVersion: permit.bcaVersion,
      buildingClassifications: permit.buildingClassification,
      riseInStoreys: permit.riseInStoreys,
      stageDetailsOfWork: permit.stageDetailsOfWork,
      storeysContained: permit.storeysContained,
      typeOfConstruction: permit.typeOfConstruction,
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...permit,
        bcaVersion: values.bcaVersion,
        buildingClassification: values.buildingClassifications,
        riseInStoreys: values.riseInStoreys,
        stageDetailsOfWork: values.stageDetailsOfWork,
        storeysContained: values.storeysContained,
        typeOfConstruction: values.typeOfConstruction,
      });
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item md={6}>
          <TextField
            id="storeysContained"
            name="storeysContained"
            label="Storeys Contained"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.storeysContained}
            error={
              formik.touched.storeysContained &&
              Boolean(formik.errors.storeysContained)
            }
            helperText={
              formik.touched.storeysContained
                ? formik.errors.storeysContained
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="riseInStoreys"
            name="riseInStoreys"
            label="Rise in Storeys"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.riseInStoreys}
            error={
              formik.touched.riseInStoreys &&
              Boolean(formik.errors.riseInStoreys)
            }
            helperText={
              formik.touched.riseInStoreys ? formik.errors.riseInStoreys : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="typeOfConstruction"
              name="typeOfConstruction"
              label="Type of Construction"
              fullWidth
              disabled={formik.isSubmitting}
              value={formik.values.typeOfConstruction}
              sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
              onChange={(event) => {
                const value = event.target.value;
                formik.setFieldValue(
                  "typeOfConstruction",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.typeOfConstruction &&
                Boolean(formik.errors.typeOfConstruction)
              }
              helperText={
                formik.touched.typeOfConstruction
                  ? formik.errors.typeOfConstruction
                  : ""
              }
              SelectProps={{
                multiple: true,
              }}
            >
              {Object.values(ConstructionType).map((p, index) => (
                <MenuItem key={`permit-type-${index}`} value={p}>
                  {constructionTypeToString(p)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="bcaVersion"
            name="bcaVersion"
            label="BCA Version"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bcaVersion}
            error={
              formik.touched.bcaVersion && Boolean(formik.errors.bcaVersion)
            }
            helperText={
              formik.touched.bcaVersion ? formik.errors.bcaVersion : ""
            }
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            id="stageDetailsOfWork"
            name="stageDetailsOfWork"
            label="Stage Details of Work Permitted"
            rows={5}
            multiline
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stageDetailsOfWork}
            error={
              formik.touched.stageDetailsOfWork &&
              Boolean(formik.errors.stageDetailsOfWork)
            }
            helperText={
              formik.touched.stageDetailsOfWork
                ? formik.errors.stageDetailsOfWork
                : ""
            }
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            id="buildingClassifications"
            name="buildingClassifications"
            label="Building Classifications"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.buildingClassifications}
            error={
              formik.touched.buildingClassifications &&
              Boolean(formik.errors.buildingClassifications)
            }
            helperText={
              formik.touched.buildingClassifications
                ? formik.errors.buildingClassifications
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "10px 0 0",
        }}
      >
        <StyledButton
          loading={false}
          btnType={ButtonType.Secondary}
          disabled={formik.isSubmitting}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          loading={false}
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
