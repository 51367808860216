import { DialogContentText, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { AcceptIcon, CloseIconV2 } from "assets/constants";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import React, { useState } from "react";

import ProgressTable from "./ProgressTable";
import { ReadOnlyCommentTable2 } from "./ReadOnlyCommentTable2";
import { ReadOnlyDocumentTable2 } from "./ReadOnlyDocumentTable2";
import { AcceptModal } from "../AcceptModal/AcceptModal";
import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";
import { RejectDialog } from "../RejectModal/RejectModal";
import { SubmitModal } from "../SubmitModal/SubmitModal";

interface ConditionReviewFormProps {
  project: ProjectLineItem;
  permit: PermitLineItem;
  loading: boolean;
  canEdit: boolean;
  condition: ConditionLineItem;
  comments: ConditionCommentLineItem[];
  documents: ConditionDocumentLineItem[];
  userProfile?: ProfileLineItem;
  onSendForReview: () => void;
  onAccept: () => void;
  onReject: (rejectionMessage: string) => void;
  handleBack: () => void;
  handleClose: () => void;
}

export const ConditionReviewForm = ({
  canEdit,
  comments,
  condition,
  documents,
  handleBack,
  handleClose,
  loading,
  onAccept,
  onReject,
  onSendForReview,
  permit,
  userProfile,
}: ConditionReviewFormProps) => {
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [openAcceptModal, setOpenAcceptModal] = React.useState(false);
  const [openSubmitModal, setOpenSubmitModal] = React.useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const handleSendForReview = async () => {
    onSendForReview();
  };

  const handleAccept = async () => {
    onAccept();
  };

  const handleReject = async (message: string) => {
    onReject(message);
  };

  const submitters = comments
    .map((c) => {
      return `${c.firstName} ${c.lastName}`;
    })
    .filter((c, i, arr) => i != arr.indexOf(c));

  const buttons: HeaderButtonConfig[] = [];

  if (
    (canEdit && condition.status === ConditionStatus.NotAssigned) ||
    condition.status === ConditionStatus.Assigned ||
    condition.status === ConditionStatus.Rejected ||
    condition.status === ConditionStatus.Overdue
  ) {
    buttons.push({
      action: async () => setOpenSubmitModal(true),
      icon: <AcceptIcon />,
      text: "Send for Approval",
      type: HeaderButtonType.PRIMARY,
    });
  }

  if (
    permit?.approvers &&
    canEdit &&
    userProfile &&
    permit.approvers.map((a) => a.id).includes(userProfile?.id) &&
    condition?.status === ConditionStatus.InReview
  ) {
    buttons.push({
      action: async () => setOpenAcceptModal(true),
      icon: <AcceptIcon />,
      text: "Accept",
      type: HeaderButtonType.SUCCESS,
    });
    buttons.push({
      action: async () => setOpenRejectModal(true),
      icon: <CloseIconV2 variant="secondary" />,
      text: "Reject",
      type: HeaderButtonType.DANGER,
    });
  }

  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "0 0 10px",
        }}
      >
        <Header
          mainTitle={"Permit Submission Review"}
          subTitle={
            canEdit
              ? "Review documents, comments and progress"
              : "View documents, comments and progress"
          }
          loading={loading}
          handleSearchChange={handleSearchChange}
          searchTerm={searchTerm}
          buttons={buttons}
        />
      </DialogContentText>
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
          <Grid container sx={{ padding: "0 " }} spacing={2}>
            <Grid item xs={12} md={8} sx={{ padding: "0 0 20px" }}>
              <Stack spacing={2}>
                <ReadOnlyDocumentTable2
                  loading={loading}
                  searchTerm={searchTerm}
                  conditionDocuments={documents}
                />
                <ReadOnlyCommentTable2
                  conditionComments={comments}
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} sx={{ padding: "0 0 20px" }}>
              <Stack spacing={2}>
                <ProgressTable
                  condition={condition}
                  submitter={submitters}
                  documents={documents}
                  comments={comments}
                  approver={permit.approvers}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <StyledButton
              loading={false}
              btnType={ButtonType.Secondary}
              onClick={handleBack}
            >
              Back
            </StyledButton>
            <StyledButton
              loading={false}
              btnType={ButtonType.Secondary}
              onClick={handleClose}
            >
              Exit
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
      {canEdit && openRejectModal ? (
        <RejectDialog
          open={openRejectModal}
          onCancel={(): void => {
            setOpenRejectModal(false);
          }}
          onConfirm={handleReject}
        />
      ) : null}
      {openAcceptModal ? (
        <AcceptModal
          open={openAcceptModal}
          onCancel={(): void => {
            setOpenAcceptModal(false);
          }}
          onConfirm={handleAccept}
        />
      ) : null}
      {canEdit && openSubmitModal ? (
        <SubmitModal
          open={openSubmitModal}
          onCancel={(): void => {
            setOpenSubmitModal(false);
          }}
          onConfirm={handleSendForReview}
        />
      ) : null}
    </>
  );
};
