import { useProjectContext } from "@hooks/context/useProjectContext";
import { Box, MenuItem, TextField } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SearchSection = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { allProjects } = useProjectContext();

  const filteredProjects =
    allProjects?.filter((project) => !project.archived) || [];

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    navigate(RouteHelper.overview(e.target.value));
  };
  return (
    <>
      <Box>
        <TextField
          id="input-search-header"
          select
          value={projectId}
          onChange={onChange}
          label="Project"
          placeholder="Projects"
          inputProps={{ "aria-label": "weight" }}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#fff",
              height: "54px",
            },
          }}
        >
          {filteredProjects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.projectName}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </>
  );
};

export default SearchSection;
