import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { QuillEditor } from "@stories/atoms/QuillEditor/QuillEditor";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
  ConditionAuthority,
  conditionAuthorityToString,
} from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useEffect, useState } from "react";
import * as yup from "yup";

interface ConditionFormProps {
  condition: ConditionLineItem;
  permit: PermitLineItem;
  permitItemNumbers: Map<ConditionAuthority, number[]>;
  handleOpenConditionDocumentModal?: (condition: ConditionLineItem) => void;
  handleSubmit: (condition: ConditionLineItem) => void;
  editMode?: boolean;
}

export const ConditionForm = ({
  condition,
  editMode = false,
  handleOpenConditionDocumentModal,
  handleSubmit,
  permit,
  permitItemNumbers,
}: ConditionFormProps) => {
  const theme = useTheme();

  const [assignees] = useState<ProfileLineItem[]>(condition.assignees);
  const [itemNumbers, setItemNumbers] = useState(new Map(permitItemNumbers));

  const getNextNumber = (authority: ConditionAuthority) => {
    const numbers = itemNumbers.get(authority) || [];
    return numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
  };

  const validationSchema = yup.object({
    assignee: yup.array(),
    conditionAuthority: yup
      .string()
      .required("Condition authority is required"),
    conditionComments: yup.string(),
    conditionDetails: yup.string(),
    discipline: yup.string().required("Discipline is required"),
    disciplineOther: yup
      .string()
      .test(
        "Required if other",
        "Discipline description required",
        function (item) {
          if (this.parent.discipline === "Other") {
            if (!item || item.length === 0) {
              return false;
            }
          }
          return true;
        }
      ),
    dueDate: yup.date().required("Condition Due Date is required"),
    permitItemNumber: yup
      .number()
      .required("Permit item number is required")
      .test("is-unique", "Permit item number must be unique", function (value) {
        const currentAuthority = this.parent.conditionAuthority;
        const existingNumbers = itemNumbers.get(currentAuthority) || [];
        return !existingNumbers.includes(value);
      }),
    status: yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assignee: condition?.assignees || [],
      conditionAuthority: condition.conditionAuthority,
      conditionComments: condition.conditionComments,
      conditionDetails: condition.conditionDetails,
      conditionName: condition.conditionName,
      discipline: condition.discipline,
      disciplineOther: condition.disciplineOther,
      dueDate: dayjs(condition.dueDate),
      permitItemNumber: condition.permitItemNumber,
      status: condition.status,
    },
    onSubmit: async (values) => {
      let conditionStatus = values.status as ConditionStatus;
      if (
        permit.managers.length > 0 &&
        values.status === ConditionStatus.NotAssigned
      ) {
        conditionStatus = ConditionStatus.Assigned;
      }

      setItemNumbers((prev) => {
        const updated = new Map(prev);
        const currentNumbers = updated.get(values.conditionAuthority) || [];
        updated.set(values.conditionAuthority, [
          ...currentNumbers,
          values.permitItemNumber,
        ]);
        return updated;
      });

      await handleSubmit({
        ...condition,
        assignees: assignees,
        conditionAuthority: values.conditionAuthority,
        conditionComments: values.conditionComments,
        conditionDetails: values.conditionDetails,
        conditionName: values.conditionName,
        discipline: values.discipline,
        disciplineOther: values.disciplineOther,
        dueDate: values.dueDate.toDate(),
        permitItemNumber: values.permitItemNumber,
        status: conditionStatus,
      });
    },
    validationSchema: validationSchema,
  });

  // const onAssigneeChange = (profiles?: ProfileLineItem[]) => {
  //   if (profiles) {
  //     setAssignees(profiles);
  //     formik.setFieldValue(
  //       "approvers",
  //       profiles.map((p) => p.id)
  //     );
  //   }
  // };

  useEffect(() => {
    if (!editMode) {
      const nextNumber = getNextNumber(formik.values.conditionAuthority);
      formik.setFieldValue("permitItemNumber", nextNumber);
    }
  }, [formik.values.conditionAuthority, itemNumbers]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} mt={1}>
          <TextField
            select
            id="conditionAuthority"
            name="conditionAuthority"
            label="Condition Authority"
            fullWidth
            disabled={formik.isSubmitting}
            value={formik.values.conditionAuthority}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
            error={
              formik.touched.conditionAuthority &&
              Boolean(formik.errors.conditionAuthority)
            }
            helperText={
              formik.touched.conditionAuthority
                ? formik.errors.conditionAuthority
                : ""
            }
          >
            {Object.values(ConditionAuthority).map((p, index) => (
              <MenuItem key={`permit-type-${index}`} value={p}>
                {conditionAuthorityToString(p)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={false}
            id="permitItemNumber"
            name="permitItemNumber"
            fullWidth
            disabled={formik.isSubmitting}
            value={formik.values.permitItemNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.permitItemNumber &&
              Boolean(formik.errors.permitItemNumber)
            }
            helperText={
              formik.touched.permitItemNumber
                ? formik.errors.permitItemNumber
                : ""
            }
            label="Permit Item Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {formik.values.conditionAuthority === ConditionAuthority.DA
                    ? "DA -"
                    : "BCA -"}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={12} className="fullwidth messageBox" mb={1}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#637381",
              fontFamily: "Roboto,sans-serif",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Discipline
          </Typography>
          <Box
            sx={{
              "& .quill": {
                "& .ql-container": {
                  "& .ql-editor": {
                    minHeight: 150,
                  },
                  backgroundColor: "white !important",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                },
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
              },
              bgcolor: theme.palette.primary[200] + 40,
              border: "1px solid",
              borderColor: theme.palette.primary.light,
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <QuillEditor
              loading={false}
              allowImages={false}
              onChange={(value: string) => {
                formik.setFieldValue("discipline", value);
              }}
              value={formik.values.discipline}
            />
            {formik.touched.discipline && Boolean(formik.errors.discipline) ? (
              <Typography color={"error"}>
                {formik.errors.discipline}
              </Typography>
            ) : undefined}
          </Box>
        </Grid>
        <Grid item md={12} className="fullwidth messageBox" mb={1}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#637381",
              fontFamily: "Roboto,sans-serif",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Condition Details
          </Typography>
          <Box
            sx={{
              "& .quill": {
                "& .ql-container": {
                  "& .ql-editor": {
                    minHeight: 150,
                  },
                  backgroundColor: "white !important",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                },
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
              },
              bgcolor: theme.palette.primary[200] + 40,
              border: "1px solid",
              borderColor: theme.palette.primary.light,
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <QuillEditor
              loading={false}
              allowImages={false}
              onChange={(value: string) => {
                formik.setFieldValue("conditionDetails", value);
              }}
              value={formik.values.conditionDetails}
            />
            {formik.touched.conditionDetails &&
            Boolean(formik.errors.conditionDetails) ? (
              <Typography color={"error"}>
                {formik.errors.conditionDetails}
              </Typography>
            ) : undefined}
          </Box>
        </Grid>
        <Grid item md={12} className="fullwidth messageBox" mb={1}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#637381",
              fontFamily: "Roboto,sans-serif",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Condition Comments
          </Typography>
          <Box
            sx={{
              "& .quill": {
                "& .ql-container": {
                  "& .ql-editor": {
                    minHeight: 150,
                  },
                  backgroundColor: "white !important",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                },
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
              },
              bgcolor: theme.palette.primary[200] + 40,
              border: "1px solid",
              borderColor: theme.palette.primary.light,
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <QuillEditor
              loading={false}
              allowImages={false}
              onChange={(value: string) => {
                formik.setFieldValue("conditionComments", value);
              }}
              value={formik.values.conditionComments}
            />

            {formik.touched.conditionComments &&
            Boolean(formik.errors.conditionComments) ? (
              <Typography color={"error"}>
                {formik.errors.conditionComments}
              </Typography>
            ) : undefined}
          </Box>
        </Grid>
        {/* <Grid item md={12}>
          <UserMultiSearch
            loading={false}
            id="assignees"
            name="assignees"
            label="Assignees"
            existingProfiles={assignees}
            disabled={formik.isSubmitting}
            onChange={(value?: ProfileLineItem[] | undefined) => {
              if (value) {
                if (formik.values.status === ConditionStatus.NotAssigned) {
                  formik.setFieldValue(
                    "status",
                    ConditionStatus.Assigned,
                    true
                  );
                }
              } else {
                formik.setFieldValue(
                  "status",
                  ConditionStatus.NotAssigned,
                  true
                );
              }
              onAssigneeChange(value);
            }}
            onBlur={formik.handleBlur}
            handleSearch={searchProfiles}
            error={formik.touched.assignee && Boolean(formik.errors.assignee)}
          />
        </Grid> */}
        {/* <Grid item md={12}>
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("dueDate", value, true);
              }
            }}
            format={"DD/MM/YYYY"}
            disabled={formik.isSubmitting}
            value={formik.values.dueDate}
            slotProps={{
              textField: {
                error: formik.touched.dueDate && Boolean(formik.errors.dueDate),
                helperText:
                  formik.touched.dueDate && formik.errors.dueDate
                    ? "Invalid date"
                    : "",
                label: "Due date",
                margin: "dense",
              },
            }}
          />
        </Grid> */}
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          display: "flex !important",
          justifyContent: "right !important;",
          padding: "10px 0 0",
        }}
      >
        <Stack spacing={2} direction="row">
          {handleOpenConditionDocumentModal ? (
            <StyledButton
              loading={false}
              disabled={formik.isSubmitting}
              onClick={() => {
                handleOpenConditionDocumentModal(condition);
              }}
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
            >
              {"Documents"}
            </StyledButton>
          ) : null}
          <StyledButton
            loading={false}
            type="submit"
            disabled={formik.isSubmitting}
          >
            Submit
          </StyledButton>
        </Stack>
      </Grid>
    </form>
  );
};
