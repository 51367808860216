import { Typography, styled } from "@mui/material";

const StyledTitle = styled(Typography)({
  color: "#181D27",
  fontFamily: '"Inter", sans-serif',
  fontSize: "24px !important",
  fontWeight: 600,
  lineHeight: "32px",
  marginTop: "-8px",
});

const StyledSubHeading = styled(Typography)({
  color: "#535862",
  fontFamily: '"Inter", sans-serif',
  fontSize: "16px",
  lineHeight: "24px",
  marginBottom: 0,
});

export { StyledSubHeading, StyledTitle };
