import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useState } from "react";
export interface ConfirmationDialogProps {
  message: string;
  open: boolean;
  title: string;
  intent: "info" | "success" | "error";
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
}
export const ConfirmationDialog = ({
  intent,
  message,
  onCancel,
  onConfirm,
  open,
  title,
}: ConfirmationDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      const result = onConfirm();
      if (result instanceof Promise) {
        await result;
      }
    } catch (error) {
      console.error("Error in onConfirm:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { maxHeight: 435, width: "80%", zIndex: 9999 },
      }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "25px 30px 20px" }}
      >
        {title || "Confirmation"}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          disabled={isSubmitting}
          sx={{
            "&:hover": {
              backgroundColor: "#000000 !important",
              opacity: 0.9,
            },
            backgroundColor: "#000000",
            color: "#fff",
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 30px 30px" }}>
        {isSubmitting ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Typography
            sx={{ background: "#fff", borderRadius: "10px", padding: "10px" }}
          >
            {message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "0 30px 30px" }}>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!isSubmitting && (
            <StyledButton
              loading={false}
              color={intent}
              variant="contained"
              onClick={handleConfirm}
              sx={{
                "&:hover": {
                  background: "#e30020 !important",
                },
                background: "red !important",
              }}
            >
              Confirm
            </StyledButton>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
