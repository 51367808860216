import { useAllFiles } from "@hooks/crud/useAllFiles";
import { useExcel } from "@hooks/crud/useExcel";
import { usePdf } from "@hooks/crud/usePdf";
import { Chip, Grid, Stack } from "@mui/material";
import ConditionExport from "@stories/molecules/ConditionExport/ConditionExport";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CellAction,
  defaultCellStyles,
  RichTextInputCell,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { AddIcon, ExportIcon } from "assets/constants";
import {
  ConditionAuthority,
  conditonNameToString,
} from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useEffect, useState } from "react";

interface PublicConditionTable2Props {
  loading: boolean;
  conditions: ConditionLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  canEdit: boolean;
  disabled: boolean;
  shareCondition?: ShareConditionLineItem;
  handleOpenConditionDocumentModal: (condition: ConditionLineItem) => void;
}

export const PublicConditionTable2 = ({
  canEdit,
  conditions,
  handleOpenConditionDocumentModal,
  loading,
  organisation,
  permit,
  profile,
  project,
}: PublicConditionTable2Props) => {
  const publicConditions = conditions.filter((condition) => {
    return (
      !condition.markedAsNotApplicable &&
      condition.status !== ConditionStatus.NotAssigned
    );
  });
  const { PDFDownloadProgress, downloadStepPDF, getPdfReport } = usePdf();
  const { downloadStepExcel, excelDownloadProgress, getExcelReport } =
    useExcel();
  const { allFilesDownloadProgress, downloadStepAllFiles, getFullReportInfo } =
    useAllFiles();

  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const [tabIndex, setTabIndex] = useState<number>(parseInt(status || "0"));
  const [searchTerm, setSearchTerm] = useState("");

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleOpenExportModal = useCallback(
    (flag: boolean) => {
      setExportModalOpen(flag);
    },
    [exportModalOpen]
  );

  const colDefs = [
    {
      field: "permitItemNumber",
      flex: 2,
      headerName: "Item",
      valueFormatter: (params: any) => {
        return conditonNameToString(
          params.data.conditionAuthority,
          params.data.permitItemNumber
        );
      },
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "conditionDetails",
      },
      cellStyle: {
        ...defaultCellStyles,
        padding: "0px",
      },
      field: "conditionDetails",
      flex: 5,
      headerName: "Comments",
      headerStyle: { backgroundColor: "white !important" },
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "discipline",
      },
      cellStyle: {
        ...defaultCellStyles,
        padding: "0px",
      },
      field: "discipline",
      flex: 5,
      headerName: "Discipline",
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "conditionComments",
      },
      cellStyle: {
        ...defaultCellStyles,
        padding: "0px",
      },
      field: "conditionComments",
      flex: 5,
      headerName: "Manager Notes",
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ({ data }: any) => (
        <Chip
          label={conditionStatusToString(
            data.status,
            Boolean(data.markedAsNotApplicable)
          )}
          sx={{
            backgroundColor: conditionStatusToColor(
              data.status,
              Boolean(data.markedAsNotApplicable)
            ),
            color: "black",
          }}
          size="small"
          variant="filled"
          className="chip"
        />
      ),
      cellStyle: centerCellStyles,
      field: "status",
      flex: 3,
      headerClass: "centered-table-header",
      headerName: "Status",
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  const buttons = [
    {
      action: async () => {
        setExportModalOpen(true);
      },
      icon: <ExportIcon />,
      text: "Download Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Assigned",
      value: 1,
    },
    {
      label: "In Review",
      value: 2,
    },
    {
      label: "Approved",
      value: 3,
    },
  ];

  const filteredConditions = (() => {
    switch (tabIndex) {
      case 1:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.Assigned
        );
      case 2:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.InReview
        );
      case 3:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.Approved
        );
      case 4:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.NotApplicable
        );
      default:
        return publicConditions;
    }
  })();

  const baConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.BCA
  );
  const daConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.DA
  );

  const cellActions = [
    {
      action: handleOpenConditionDocumentModal,
      icon: <AddIcon />,
      title: "Add Documents",
    },
  ] as CellAction[];

  useEffect(() => {
    if (status) {
      setTabIndex(parseInt(status));
    }
  }, [status]);

  return (
    <Grid item xs={12} md={12} border={"1px solid #e9eaeb"} borderRadius="12px">
      <Stack direction="column">
        <Header
          loading={loading}
          mainTitle={"Conditions"}
          isSubheading
          buttons={buttons}
          handleSearchChange={handleSearchChange}
          tabs={tabs}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
          searchTerm={searchTerm}
        />
        <Grid
          sx={{
            borderBottom: "1px solid #e9eaeb",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <Header
            loading={loading}
            mainTitle={""}
            subTitle={"BA Conditions"}
            isSubheading
            paddingTop="12px"
          />
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={baConditions}
            rowHeight={100}
            noFullTableBorder
            context={{
              canEdit,
              cellActions,
              locked: true,
              organisation,
              profile,
            }}
          />
        </Grid>
        <Header
          loading={loading}
          mainTitle={""}
          subTitle={"DA Conditions"}
          isSubheading
        />
        <Table
          columnDefs={colDefs}
          loading={loading}
          data={daConditions}
          noFullTableBorder
          context={{
            canEdit,
            cellActions,
            locked: true,
            organisation,
            profile,
          }}
        />
      </Stack>
      {project && permit && exportModalOpen ? (
        <ConditionExport
          project={project}
          permit={permit}
          loading={loading}
          open={exportModalOpen}
          setOpen={handleOpenExportModal}
          getPdfReport={getPdfReport}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}
    </Grid>
  );
};
