import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import { Header } from "@stories/organisms/Header/Header";
import { OrganisationForm } from "@stories/organisms/OrganisationForm/OrganisationForm";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { ProfileForm } from "@stories/organisms/ProfileForm/ProfileForm";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";

import { PermitLicenses } from "./PermitLicenses";
import { Users } from "../Users/Users";

export const UserAccount = () => {
  const { user } = useAuthContext();

  if (!user) {
    throw Error("No user in state.");
  }

  const params = new URLSearchParams(location.search);
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(params.get("status") || "0")
  );

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const {
    isAuthProfileLoading,
    updateUserOrganisation,
    updateUserProfile,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmitProfile = async (profile: ProfileLineItem) => {
    if (userProfile) {
      await updateUserProfile(profile);
    }
  };

  const handleSubmitOrganisation = async (org: OrganisationLineItem) => {
    if (userProfile) {
      await updateUserOrganisation(org, userProfile);
    }
  };

  const loading = isAuthProfileLoading;

  const selectedSettingStep = (() => {
    switch (tabIndex) {
      default:
      case 0:
        return (
          <ProfileForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmitProfile}
          />
        );
      case 1:
        return (
          <OrganisationForm
            organisation={userOrganisation}
            handleSubmit={handleSubmitOrganisation}
            loading={loading}
          />
        );
      case 2:
        return <Users />;
      case 3:
        return <PermitLicenses loading={loading} />;
    }
  })();

  const tabs = [
    {
      label: "Profile",
    },
  ];

  if (userProfile?.isAdmin) {
    tabs.push(
      ...[
        {
          label: "Business Settings",
        },
        {
          label: "Users",
        },
        // {
        //   label: "Permit Log",
        // },
      ]
    );
  }
  return (
    <PageContainer>
      <Header
        subTitle={" "}
        mainTitle={"Account Management"}
        loading={loading}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />

      <Grid item md={12}>
        {selectedSettingStep}
      </Grid>
    </PageContainer>
  );
};
