import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useCondition } from "@hooks/crud/useCondition";
import { usePermit } from "@hooks/crud/usePermit";
import { Grid, styled } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import ShareCondition from "@stories/molecules/ShareCondition/ShareCondition";
import ConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/ConditionDocumentModal";
import ConditionModal from "@stories/organisms/ConditionModal/ConditionModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { PermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import { PermitEventLog } from "@stories/organisms/PermitEventLog/PermitEventLog";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { ConditionTable2 } from "@stories/organisms/Tables/ConditionTable/ConditionTable2";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import * as RouteHelper from "@utils/routes";
import { AddEmployeeIcon, AddIcon, UserIcon } from "assets/constants";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import { PermitStatus } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

export const Conditions = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReadyForApproval, setIsReadyForApproval] = useState(false);
  const { permitId } = useParams();
  const [openBulk, setOpenDocumentModal] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const { getPermit, isPermitLoading, selectedPermit, updatePermit } =
    usePermit(undefined, permitId);

  const {
    conditions,
    createCondition,
    createShareConditions,
    // deleteCondition,
    deleteFewConditions,
    importConditions,
    isConditionLoading,
    isConditionUpdating,
    toggleShareCondition,
    updateCondition,
  } = useCondition(undefined, permitId);

  const [selectedCondition, setSelectedCondition] = useState<
    ConditionLineItem | undefined
  >(undefined);
  const [open, setOpenConditionModal] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = useCallback(async (tabIndex: number) => {
    setTabIndex(tabIndex);
  }, []);

  useEffect(() => {
    if (!isConditionLoading && searchParams.has("id") && conditions.length) {
      const conditionId = searchParams.get("id");
      const condition = conditions.find((c) => c.id === conditionId);
      if (condition) {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete("id");
        setSearchParams(newSearchParams);
        handleEditCondition(condition);
      }
    }
  }, [isConditionLoading, searchParams, conditions]);

  useEffect(() => {
    const isPermitReadyForApproval =
      conditions.length > 0 &&
      conditions.every(
        (item) =>
          item.status === ConditionStatus.Approved || item.markedAsNotApplicable
      ) &&
      selectedPermit?.status !== PermitStatus.Complete;
    setIsReadyForApproval(isPermitReadyForApproval);
  }, [conditions]);

  useEffect(() => {
    setSearchParams();
  }, []);

  const handleToggleShareCondition = useCallback(
    async (collaborator: DirectoryContactLineItem) => {
      await toggleShareCondition(collaborator.id, true);
    },
    [conditions]
  );

  const handleSaveCondition = useCallback(
    async (condition: ConditionLineItem) => {
      await updateCondition(condition);
    },
    [conditions]
  );

  const handleCloseConditionEditModal = useCallback(() => {
    setOpenConditionModal(false);
    setSelectedCondition(undefined);
  }, []);

  const handleEditCondition = useCallback(
    async (selecetedCondition?: ConditionLineItem) => {
      setOpenDocumentModal(false);
      setSelectedCondition(selecetedCondition);
      setOpenConditionModal(true);
    },
    []
  );

  const handleMarkAsNotApplicable = useCallback(
    async (selecetedCondition: ConditionLineItem) => {
      await updateCondition({
        ...selecetedCondition,
        markedAsNotApplicable: !selecetedCondition?.markedAsNotApplicable,
      });
    },
    [conditions]
  );

  const handleOpenConditionDocumentModal = useCallback(
    (condition: ConditionLineItem) => {
      setOpenConditionModal(false);
      setSelectedCondition(condition);
      setOpenDocumentModal(true);
    },
    [conditions]
  );

  const handleCloseConditionDocumentModal = useCallback(
    (condition?: ConditionLineItem) => {
      setOpenDocumentModal(false);
      setSelectedCondition(condition);
    },
    [conditions]
  );

  const handleImportConditons = useCallback(
    async (conditions: ConditionLineItem[]) => {
      await importConditions(conditions);
      // setImportedConditions(conditions);
    },
    [conditions]
  );

  const handleCreateCondition = useCallback(
    async (condition: ConditionLineItem) => {
      createCondition(condition).then(async () => {
        if (permitId) {
          await Promise.all([getPermit(permitId, true)]);
        }
      });
    },
    [conditions]
  );

  const handleUpdateCondition = useCallback(
    async (condition: ConditionLineItem) => {
      updateCondition(condition).then(async () => {
        if (permitId) {
          await Promise.all([getPermit(permitId, true)]);
        }
      });
    },
    [conditions]
  );

  const handleDeleteCondition = useCallback(
    async (conditions: ConditionLineItem[]) => {
      deleteFewConditions(conditions.map((c) => c.id)).then(async () => {
        if (permitId) {
          await getPermit(permitId);
        }
      });
    },
    [conditions]
  );

  const handleApprovePermit = (permit: PermitLineItem) => {
    const updatedPermit = { ...permit, status: PermitStatus.Complete };

    if (permitId) {
      updatePermit(updatedPermit).then(async () => {
        await getPermit(permitId);
        setIsReadyForApproval(false);
      });
    }
  };

  const handleShareConditions = async (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accessLevel: ShareConditionAccessLevel
  ): Promise<void> => {
    await createShareConditions(
      email,
      firstName,
      lastName,
      projectId,
      permitId,
      accessLevel
    );
  };

  // const handleViewCondition = useCallback(
  //   (condition: ConditionLineItem) => {
  //     if (selectedProject && selectedPermit) {
  //       navigate(
  //         RouteHelper.condition(
  //           selectedProject.id,
  //           selectedPermit.id,
  //           condition.id
  //         )
  //       );
  //     } else {
  //       throw Error("No condition or condition selected");
  //     }
  //   },
  //   [selectedProject, selectedPermit]
  // );

  const loading =
    isAuthProfileLoading ||
    isProjectLoading ||
    isPermitLoading ||
    isConditionLoading;
  // const disabled = isConditionUpdating;
  const selectedConditionStep = (() => {
    switch (tabIndex) {
      default:
      case 0:
        return (
          <ConditionTable2
            loading={loading}
            disabled={isConditionUpdating}
            conditions={conditions}
            handleImportConditons={handleImportConditons}
            handleEditCondition={handleEditCondition}
            handleDeleteCondition={handleDeleteCondition}
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
            handleMarkAsNotApplicable={handleMarkAsNotApplicable}
            organisation={userOrganisation}
            profile={userProfile}
            project={selectedProject}
            handleConditionSave={handleSaveCondition}
            permit={selectedPermit}
          />
          // <ConditionTable
          //   handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
          //   handleOpenConditionEditModal={handleOpenConditionEditModal}
          //   loading={loading}
          //   disabled={disabled}
          //   profile={userProfile}
          //   userOrganisation={userOrganisation}
          //   project={selectedProject}
          //   permit={selectedPermit}
          //   conditions={conditions}
          //   handleDeleteFewConditions={handleDeleteFewConditions}
          //   handleImportConditons={handleImportConditons}
          //   handleViewCondition={handleViewCondition}
          //   handleEditCondition={handleUpdateCondition}
          //   handleDeleteCondition={handleDeleteCondition}
          //   handleShareConditions={handleShareConditions}
          // />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      // case 2:
      //   return (
      //     <MailCenter
      //       isLoading={loading}
      //       project={selectedProject}
      //       permit={selectedPermit}
      //       profile={userProfile}
      //       showDialog={false}
      //     />
      //   );
      case 2:
        return (
          <PermitCollaborators
            loading={loading}
            permit={selectedPermit}
            project={selectedProject}
            profile={userProfile}
            handleToggleShareCondition={handleToggleShareCondition}
          />
        );
      case 3:
        return <PermitEventLog loading={loading} permit={selectedPermit} />;
      case 5:
        return (
          <InspectionTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
    }
  })();

  const handlOnBack = () => {
    if (selectedProject) {
      navigate(RouteHelper.permits(selectedProject.id));
    }
  };

  const canEdit =
    (selectedProject &&
      userOrganisation &&
      selectedProject?.organisationId === userOrganisation?.id) ||
    false;

  const existingIds = new Map<ConditionAuthority, number[]>();
  conditions.forEach((condition) => {
    if (condition.conditionAuthority in existingIds) {
      existingIds
        .get(condition.conditionAuthority)
        ?.push(condition.permitItemNumber);
    } else {
      existingIds.set(condition.conditionAuthority, [
        condition.permitItemNumber,
      ]);
    }
  });

  const tabs = [
    {
      label: "Checklist",
    },
    {
      label: "Permit Details",
    },
    {
      label: "Contacts",
    },
    {
      label: "Event Log",
    },
  ];
  const buttons: HeaderButtonConfig[] = [
    {
      action: async () => {
        setShareOpen(true);
      },
      icon: <AddEmployeeIcon variant="secondary" />,
      text: "Share Checklist",
      type: HeaderButtonType.PRIMARY,
      width: 160,
    },
  ];
  if (
    isReadyForApproval &&
    userOrganisation &&
    userOrganisation.id === selectedProject?.organisationId
  ) {
    buttons.push(
      ...[
        {
          action: async () => handleEditCondition(),
          icon: <UserIcon variant="secondary" />,
          text: "Share Checklist",
          type: HeaderButtonType.PRIMARY,
          width: 150,
        },
        {
          action: async () => handleEditCondition(),
          icon: <AddIcon variant="secondary" />,
          text: "Add Condition",
          type: HeaderButtonType.PRIMARY,
          width: 150,
        },
      ]
    );
  }

  return (
    <PageContainer>
      <Header
        subTitle={selectedPermit?.permitName || "PermitOne Permits"}
        mainTitle={"Conditions"}
        loading={loading}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        handleBack={handlOnBack}
        buttons={buttons}
      />
      <Grid item md={12}>
        <ConditionSummary
          conditionCounts={{
            approved: conditions.filter(
              (c) =>
                c.status === ConditionStatus.Approved &&
                !c.markedAsNotApplicable
            ).length,
            assigned: conditions.filter(
              (c) =>
                c.status === ConditionStatus.Assigned &&
                !c.markedAsNotApplicable
            ).length,
            inReview: conditions.filter(
              (c) =>
                c.status === ConditionStatus.InReview &&
                !c.markedAsNotApplicable
            ).length,
            notApplicable: conditions.filter((c) => c.markedAsNotApplicable)
              .length,
            notAssigned: conditions.filter(
              (c) =>
                c.status === ConditionStatus.NotAssigned &&
                !c.markedAsNotApplicable
            ).length,
            overdue: conditions.filter(
              (c) =>
                c.status === ConditionStatus.Overdue && !c.markedAsNotApplicable
            ).length,
            rejected: conditions.filter(
              (c) =>
                c.status === ConditionStatus.Rejected &&
                !c.markedAsNotApplicable
            ).length,
          }}
          project={selectedProject}
          permitId={permitId}
          loading={loading}
        />
      </Grid>

      <Grid item md={12}>
        <WhiteBox>{selectedConditionStep}</WhiteBox>

        {selectedProject && selectedPermit ? (
          <ConditionModal
            open={open}
            handleOpenConditionDocumentModal={
              selectedCondition ? handleOpenConditionDocumentModal : undefined
            }
            project={selectedProject}
            permit={selectedPermit}
            permitItemNumbers={existingIds}
            conditionCount={conditions.length}
            handleClose={handleCloseConditionEditModal}
            createCondition={handleCreateCondition}
            updateCondition={handleUpdateCondition}
            existingCondition={selectedCondition}
          />
        ) : null}
        {userProfile &&
        selectedProject &&
        selectedPermit &&
        selectedCondition &&
        openBulk ? (
          <ConditionDocumentModal
            open={openBulk}
            canEdit={canEdit}
            project={selectedProject}
            permit={selectedPermit}
            condition={selectedCondition}
            userProfile={userProfile}
            handleClose={handleCloseConditionDocumentModal}
            handleEditCondition={handleUpdateCondition}
          />
        ) : null}
      </Grid>
      <ConfirmationDialog
        message={"Are you sure you want to approve this permit?"}
        open={isApproveModalOpen}
        title={"Approve Permit"}
        intent={"success"}
        onCancel={() => {
          setIsApproveModalOpen(false);
        }}
        onConfirm={async () => {
          handleApprovePermit(selectedPermit as PermitLineItem);
          setIsApproveModalOpen(false);
        }}
      />
      {selectedProject && selectedPermit && shareOpen ? (
        <ShareCondition
          permit={selectedPermit}
          loading={false}
          disabled={false}
          open={shareOpen}
          setOpen={setShareOpen}
          shareConditions={handleShareConditions}
        />
      ) : null}
    </PageContainer>
  );
};
