import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import {
  shareCodntionAccessLevelToString,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import * as yup from "yup";
interface ShareConditionProps {
  loading: boolean;
  disabled: boolean;
  open: boolean;
  permit: PermitLineItem;
  shareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accesLevel: ShareConditionAccessLevel
  ) => Promise<void>;
  setOpen: (open: boolean) => void;
}
export default function ShareCondition({
  disabled,
  loading,
  open,
  permit,
  setOpen,
  shareConditions,
}: ShareConditionProps) {
  const validationSchema = yup.object({
    accessLevel: yup.string().required("Access level is required"),
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accessLevel: ShareConditionAccessLevel.Read,
      email: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: async (values) => {
      await shareConditions(
        values.email,
        values.firstName,
        values.lastName,
        permit.projectId,
        permit.id,
        values.accessLevel
      );
      setOpen(false);
    },
    validationSchema: validationSchema,
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="bgBlueOverlay"
      maxWidth={"sm"}
    >
      <DialogTitle>Share Checklist</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <StyledTextField
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                loading={loading}
                margin="dense"
                disabled={formik.isSubmitting || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName ? formik.errors.firstName : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                loading={loading}
                margin="dense"
                disabled={formik.isSubmitting || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName ? formik.errors.lastName : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                id="email"
                name="email"
                label="Email Address"
                fullWidth
                margin="dense"
                autoComplete="email"
                loading={loading}
                disabled={formik.isSubmitting || disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  id="accessLevel"
                  name="accessLevel"
                  margin="dense"
                  disabled={formik.isSubmitting}
                  value={formik.values.accessLevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.accessLevel &&
                    Boolean(formik.errors.accessLevel)
                  }
                  helperText={
                    formik.touched.accessLevel ? formik.errors.accessLevel : ""
                  }
                  label="Access Level"
                >
                  {Object.values(ShareConditionAccessLevel).map((al) => (
                    <MenuItem key={al} value={al}>
                      {shareCodntionAccessLevelToString(al)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <StyledButton
          loading={loading}
          variant="contained"
          disabled={disabled || loading}
          onClick={formik.submitForm}
          startIcon={<ForwardToInboxIcon />}
        >
          Share
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
