"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPermitApprover = exports.isPermitManager = exports.createDefaultPermit = exports.permitStatusToColor = exports.permitStatusToString = void 0;
var uuid_1 = require("uuid");
var EntityPermit = __importStar(require("../entities/permit"));
var permitStatusToString = function (permitStatus) {
    switch (permitStatus) {
        case EntityPermit.PermitStatus.Active:
            return "Active";
        case EntityPermit.PermitStatus.Complete:
            return "Approved";
        case EntityPermit.PermitStatus.Archived:
            return "Archived";
        case EntityPermit.PermitStatus.InReview:
            return "In Review";
    }
};
exports.permitStatusToString = permitStatusToString;
var permitStatusToColor = function (status) {
    switch (status) {
        default:
        case EntityPermit.PermitStatus.Active:
            return "#BEE1F4";
        case EntityPermit.PermitStatus.Complete:
            return "#AEE2AE";
        case EntityPermit.PermitStatus.InReview:
            return "#FCDECA";
        case EntityPermit.PermitStatus.Archived:
            return "#FCC8CF";
    }
};
exports.permitStatusToColor = permitStatusToColor;
var createDefaultPermit = function (organisation, profile, project, permitNumber) {
    return {
        abn: "",
        applicantContactPersonEmail: "",
        applicantContactPersonName: "",
        applicantContactPersonPhone: "",
        applicantCountry: "AU",
        applicantName: "",
        applicantPostCode: "",
        applicantState: "",
        applicantStreetName: "",
        applicantStreetNumber: "",
        applicantSuburb: "",
        approvedConditions: 0,
        approvers: [],
        archived: false,
        assignedConditions: 0,
        bcaVersion: "",
        buildingClassification: "",
        created: new Date(),
        id: (0, uuid_1.v4)(),
        inReviewConditions: 0,
        landCountry: "AU",
        landDpsMpsNumber: "",
        landLotNumber: "",
        landPostCode: "",
        landState: "",
        landStreetName: "",
        landStreetNumber: "",
        landSuburb: "",
        managers: [],
        notAssignedConditions: 0,
        overDueConditions: 0,
        ownerAbn: "",
        ownerApplicant: EntityPermit.OwnerApplicant.Applicant,
        ownerApplicantName: "",
        ownerContactPersonEmail: "",
        ownerContactPersonName: "",
        ownerContactPersonPhone: "",
        ownerCountry: "AU",
        ownerPostCode: "",
        ownerState: "",
        ownerStreetName: "",
        ownerStreetNumber: "",
        ownerSuburb: "",
        permitDescription: "",
        permitName: "",
        permitNumber: permitNumber + 1,
        permitScope: "",
        permitType: EntityPermit.PermitType.ConstructionCertificate,
        permitTypeOther: "",
        projectId: project.id,
        rejectedConditions: 0,
        riseInStoreys: "",
        stageDetailsOfWork: "",
        startDate: new Date(),
        status: EntityPermit.PermitStatus.Active,
        storeysContained: "",
        totalConditions: 0,
        transmittalNumber: 0,
        typeOfConstruction: [],
    };
};
exports.createDefaultPermit = createDefaultPermit;
var isPermitManager = function (permit, profile) {
    return permit && profile && permit.managers.includes(profile);
};
exports.isPermitManager = isPermitManager;
var isPermitApprover = function (permit, profile) {
    return permit.approvers.includes(profile);
};
exports.isPermitApprover = isPermitApprover;
