import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { QuillInput } from "@stories/atoms/QuillInput/QuillInput";
import Tippy from "@tippyjs/react";
import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { DropdownIcon } from "assets/constants";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { formatDecimalPlaces } from "permit-one-common/src/utils/number";
import { cleanRichText } from "permit-one-common/src/utils/string";
import { ChangeEvent, FocusEvent, useCallback, useRef, useState } from "react";
import { Editor } from "react-editor";

export const centerCellStyles = {
  alignItems: "center",
  color: "#FF00000",
  cursor: "pointer",
  display: "flex",

  justifyContent: "center !important",
};

export const defaultCellStyles = {
  alignItems: "left",
  cursor: "pointer",
  display: "flex",
  justifyContent: "left",
};

export const defaultCellConfig: ColDef = {
  cellStyle: defaultCellStyles,
  flex: 1,
  resizable: false,
  suppressKeyboardEvent: () => true,
  wrapHeaderText: true,
};

export interface CellAction {
  title: string;
  icon: React.ReactNode;
  action: (data: any) => Promise<void>;
  disabled?: boolean;
  adminOnly?: boolean;
  singleAction?: boolean;
  validateEnabled?: (data: any) => boolean;
}
interface ActionCellProps extends CustomCellRendererProps {
  text: string;
}

export const ActionCellUriToggle = (props: ActionCellProps) => {
  const { context, data, text } = props;

  function handleClick() {
    context.handleAction(data);
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      disabled={context.loading || !data.uri}
    >
      {text}
    </Button>
  );
};

export const ProgressCell = (props: CustomCellRendererProps) => {
  const progress = props.data.progress ?? props.data.estimatedProgress ?? 0;
  const clampedProgress = Math.min(100, Math.max(0, progress));
  return (
    <>
      <span
        style={{
          fontWeight: 500,
          textAlign: "left", // Aligns text to the right
          width: "50px", // Fixed width for text ensures consistent layout
        }}
      >
        {formatDecimalPlaces(clampedProgress, 0)}%
      </span>

      <LinearProgress
        variant={"determinate"}
        value={clampedProgress}
        sx={{ display: "block", flex: 1 }}
      />
    </>
  );
};

export const AvatarNameCellRenderer = (props: CustomCellRendererProps) => {
  const { firstName, lastName, organisationName, photoUrl } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Avatar
        src={photoUrl}
        alt={organisationName}
        style={{
          backgroundColor: "#ffffff",
          height: 24,
          marginRight: "8px",
          width: 24,
        }}
        sizes="small"
      />
      <span>
        {firstName} {lastName}
      </span>
    </Box>
  );
};

export const HeaderSubHeaderCell = (props: CustomCellRendererProps) => {
  const { header, subheader } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Typography variant="h6">{header}</Typography>
        <Typography variant="body2">{subheader}</Typography>
      </Stack>
    </Box>
  );
};

export const HeaderCheckboxCell = (props: any) => {
  const handleClick = () => {
    props.context.handleSelectAllDocuments();
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Checkbox
          checked={props.context.allAreSelected}
          onChange={handleClick}
        />
      </Stack>
    </Box>
  );
};

export const CheckboxCell = (props: CustomCellRendererProps) => {
  const { context, data } = props;
  const selected = context?.selectedIds?.includes(data.id) || false;

  const handleCheck = () => {
    props.context.handleCheck(data);
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Checkbox checked={selected} onChange={handleCheck} />
      </Stack>
    </Box>
  );
};

export const IconActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <TableCell>
      <span
        className={data.archived ? "archivedIcon" : "checkIcon"}
        style={{ boxShadow: "none", height: "12px", width: "12px" }}
      />
    </TableCell>
  );
};

export const ProjectNameActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Stack direction="column" spacing={0}>
      <Typography variant="body2" fontWeight={500} color={"#181D27"}>
        {data.projectName || ""}
      </Typography>
      <Typography variant="caption">{data.projectCode || ""}</Typography>
    </Stack>
  );
};

interface CollaboratorsActionCellProps extends CustomCellRendererProps {
  paramKey: string;
}

export const CollaboratorsActionCell = ({
  data,
  paramKey,
}: CollaboratorsActionCellProps) => {
  const profiles = data[paramKey] || [];
  return profiles.length > 0 ? (
    <AvatarGroup
      max={5}
      sx={{
        "& .MuiAvatar-root": {
          fontSize: "10px",
          height: 23,
          width: 23,
        },
        margin: "auto",
      }}
    >
      {profiles.map((profile: ProfileLineItem, index: number) => {
        return (
          <Tooltip
            key={index}
            title={`${profile.firstName} ${profile.lastName}`}
          >
            <Avatar alt={`${index}`} src={profile.photoUrl} />
          </Tooltip>
        );
      })}
    </AvatarGroup>
  ) : (
    <Typography align="center">-</Typography>
  );
};

export const ActionCell = ({
  context: { cellActions, disabled },
  data,
}: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!cellActions?.length) {
    return <></>;
  }

  const enabledButtons = cellActions.filter((action: CellAction) => {
    const activeValue = action.validateEnabled
      ? action.validateEnabled(data)
      : true;
    if (!activeValue) {
      return false;
    }
    return true;
  });

  if (data.isTotalRow || !enabledButtons?.length) {
    return <> </>;
  }

  if (cellActions?.length === 1 && cellActions[0]?.singleAction) {
    const action = cellActions[0];
    return (
      <Button
        sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
        disabled={action.disabled || disabled}
        disableRipple
        variant="text"
        onClick={() => action.action(data)}
      >
        {action.title}
      </Button>
    );
  }

  return cellActions && cellActions.length ? (
    <>
      <Box
        sx={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <IconButton
          disableRipple
          disabled={disabled}
          onClick={handleClick}
          aria-label="more"
          size="large"
        >
          <DropdownIcon />
        </IconButton>
      </Box>
      <Menu
        className="actionlist"
        disableScrollLock
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiMenu-paper ul li": {
            m: 0,
            p: 0,
          },
          "& .MuiMenu-paper ul li:focus": {
            backgroundColor: "transparent !important",
          },
          "& .MuiMenu-paper ul li:hover": {
            backgroundColor: "transparent !important",
            borderRadius: 0,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              border: "1px solid #E9EAEB",
              borderRadius: "10px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              mt: 0.5,
              overflow: "visible",
              padding: "12px",
            },
          },
        }}
      >
        {enabledButtons.map(
          (action: CellAction, index: number, arr: CellAction[]) => {
            return [
              <MenuItem
                key={`${index}-menu-item`}
                disableRipple
                onClick={() => {
                  action.action(data);
                  setAnchorEl(null);
                }}
                sx={{ p: 0 }}
                disabled={action.disabled}
              >
                <Box sx={{ alignItems: "center", display: "flex", gap: "8px" }}>
                  {action.icon}
                  <Typography
                    color="#181D27"
                    sx={{
                      fontFamily: "'Inter'",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    {action.title}
                  </Typography>
                </Box>
              </MenuItem>,
              arr.length > 1 && index < arr.length - 1 ? (
                <Divider
                  key={`${index}-divider`}
                  orientation="horizontal"
                  sx={{ backgroundColor: "#D5D7DA" }}
                />
              ) : null,
            ].filter(Boolean);
          }
        )}
      </Menu>
    </>
  ) : null;
};

interface RichTextInputCellProps extends CustomCellRendererProps {
  context: {
    canEdit: boolean;
    locked: boolean;
    disabled: boolean;
    handleConditionSave: (data: any) => void;
    paramKey: string;
  };
  paramKey: string;
}

export const RichTextInputCell = ({
  // api,
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const { canEdit, disabled, handleConditionSave, locked } = context;

  // const [initialRender, setIntialRender] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [value, setValue] = useState(data[paramKey]);
  const displayValue = data ? data[paramKey] : {};
  const editorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {!locked && canEdit && enabled ? (
        <ClickAwayListener onClickAway={() => setEnabled(false)}>
          <Box
            ref={editorRef}
            style={{
              height: "auto",
              overflowY: "visible",
              whiteSpace: "normal",
              width: "100%",
            }}
          >
            <QuillInput
              loading={false}
              disabled={disabled}
              value={value}
              onChange={(value) => setValue(value)}
              onBlur={async () => {
                setValue(value);
                await handleConditionSave({
                  ...data,
                  [paramKey]: cleanRichText(value),
                });
                setEnabled(false);
              }}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Box
          position="relative"
          style={{
            height: "auto",
            padding: "8px",
            width: "100%",
          }}
        >
          <Box
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "8px",
              width: "100%",
            }}
            onClick={() => setEnabled(true)}
            dangerouslySetInnerHTML={{ __html: displayValue }}
          />
          {!enabled && !locked ? (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "scale(0.7)",
              }}
            >
              <EditIcon />
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export const RichTextInputCell2 = ({
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const {
    canEdit,
    disabled,
    // handleRichTextChange,
    // handleRichTextSave,
    locked,
  } = context;

  const displayValue = data ? data[paramKey] : {};
  return (
    <>
      {!locked && canEdit ? (
        <div style={{ height: "auto", width: "100%" }}>
          <Editor
            placeholder="Type message to send..."
            value={data[paramKey]}
          />
          <EditIcon
            style={{
              color: disabled ? "#b4b4b4" : undefined,
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              right: 0,
              top: 0,
              width: "20px",
            }}
          />
        </div>
      ) : (
        <div>
          <div
            style={{
              width: "95%",
            }}
            dangerouslySetInnerHTML={{
              __html: displayValue || "",
            }}
          />
          <LockIcon
            style={{
              color: disabled ? "#b4b4b4" : undefined,
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              right: 0,
              top: 0,
              width: "20px",
            }}
          />
        </div>
      )}
    </>
  );
};

export const PopupCellRenderer = ({
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const { canEdit, disabled, locked } = context;

  const [value, setValue] = useState(data[paramKey]); // Local state for editing
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);

  const hide = () => {
    // Save data before hiding
    data[paramKey] = value; // Persist the value to `data`
    setVisible(false);
    console.log("Saved value:", value); // Debugging purpose
  };

  const dropDownContent =
    !locked && canEdit ? (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "6px",
          height: "200px",
          width: "200px",
        }}
      >
        <QuillInput
          loading={false}
          disabled={disabled}
          value={value}
          onChange={(newValue) => setValue(newValue)} // Update local state
          onBlur={() => {
            // Save onBlur when the editor loses focus
            data[paramKey] = value;
            console.log("Saved on blur:", value);
          }}
        />
      </div>
    ) : null;

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide} // Save when clicking outside
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="left"
    >
      <div
        style={{
          height: "100%",
          overflowY: "auto",
          padding: "8px",
        }}
        onClick={show} // Open dropdown
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </Tippy>
  );
};

interface InputTextFieldProps extends CustomCellRendererProps {
  context: {
    canEdit: boolean;
    disabled: boolean;
    locked: boolean;
    updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  };
  paramKey: string;
}

export const InputTextField = ({
  context,
  data,
  paramKey,
}: InputTextFieldProps) => {
  const { disabled, updateDocuments } = context;

  const [text, setText] = useState(data[paramKey]);

  const handleChangeText = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
    },
    []
  );
  const handleSaveText = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      setText(e.target.value);
      await updateDocuments([
        {
          ...data,
          [paramKey]: e.target.value,
        },
      ]);
    },
    []
  );
  return (
    <Box sx={{ padding: "20px" }}>
      <TextField
        fullWidth
        disabled={disabled}
        id="documentTitle"
        name="documentTitle"
        value={text}
        onChange={handleChangeText}
        onBlur={handleSaveText}
      />
    </Box>
  );
};

interface DropDownFieldProps extends CustomCellRendererProps {
  context: {
    canEdit: boolean;
    disabled: boolean;
    locked: boolean;
    updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  };
  paramKey: string;
  label: string;
  placeholder: string;
  options: any[];
}

export const DropDownField = ({
  context,
  data,
  options,
  paramKey,
}: DropDownFieldProps) => {
  const { updateDocuments } = context;

  const [text, setText] = useState(data[paramKey]);

  const handleSaveText = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      setText(e.target.value);
      await updateDocuments([
        {
          ...data,
          [paramKey]: e.target.value,
        },
      ]);
    },
    []
  );
  return (
    <TextField
      id="input-search-header"
      select
      value={text}
      onChange={handleSaveText}
      placeholder="placholder"
      inputProps={{ "aria-label": "weight" }}
      fullWidth
    >
      {options?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
