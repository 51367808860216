import { Chip, Grid, Stack, styled } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CellAction,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { permitTypeToString } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  getProfileName,
  ProfileLineItem,
} from "permit-one-common/src/interfaces/profile";
import {
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

interface TaskTable2Props {
  loading: boolean;
  tasks: ConditionLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchTerm: string;
  handleViewtask: (document: ConditionLineItem) => void;
}

export const TaskTable2 = ({
  handleViewtask,
  loading,
  organisation,
  profile,
  searchTerm,
  tasks,
}: TaskTable2Props) => {
  // const [selectedProject, setSelectedProject] =
  //   useState<ProjectLineItem | null>(null);
  const cellActions = [
    {
      action: handleViewtask,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View Task",
    },
  ] as CellAction[];
  const colDefs = [
    {
      cellClass: "center-ag-cell",
      cellRenderer: ({ data }: any) => (
        <Chip
          label={conditionStatusToString(
            data.status,
            Boolean(data.markedAsNotApplicable)
          )}
          sx={{
            backgroundColor: conditionStatusToColor(
              data.status,
              Boolean(data.markedAsNotApplicable)
            ),
            color: "black",
          }}
          variant="filled"
          className="chip"
        />
      ),
      cellStyle: centerCellStyles,
      field: "status",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Status",
    },
    {
      cellStyle: { cursor: "pointer" },
      field: "permitName",
      flex: 3,
      headerName: "Permit Name",
      valueFormatter: (params) =>
        params.data.projectSector !== ProjectSector.Other
          ? projectSectorToString(params.data.projectSector)
          : params.data.projectSectorOther,
    },
    {
      field: "permitType",
      flex: 3,
      headerName: "Permit Type",
      valueFormatter: (params: any) => {
        return permitTypeToString(params.data.permitType);
      },
    },
    {
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: params.data?.conditionDetails || "",
              }}
            />
          </div>
        );
      },
      field: "conditionComments",
      flex: 3,
      headerName: "Condition Details",
    },
    {
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: params.data?.discipline || "",
              }}
            />
          </div>
        );
      },
      field: "dicipline",
      flex: 3,
      headerName: "Discipline",
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "approvers",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Approver",
      valueFormatter: (params: any) => {
        return params?.data?.assignees?.length
          ? params.data.assignees.map((a: any) => getProfileName(a)).join(", ")
          : conditionStatusToString(ConditionStatus.NotAssigned, false);
      },
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "managers",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Manager",
      valueFormatter: (params: any) => {
        return params?.data?.managers?.length
          ? params.data.manager.map((a: any) => getProfileName(a)).join(", ")
          : conditionStatusToString(ConditionStatus.NotAssigned, false);
      },
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  return (
    <Grid item xs={12} md={12} mt={2}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={tasks}
            searchValue={searchTerm}
            context={{
              cellActions,
              organisation,
              profile,
            }}
            searchKeys={[
              "permitName",
              "permitType",
              "conditionComments",
              "assignees",
              "managers",
            ]}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
