import { useState } from "react";

interface InitialState {
  open: boolean;
  title: string;
  message: string;
  intent: "info" | "success" | "error";
  onConfirm: () => void | Promise<void>;
}

const defaultOnConfirm = () => {
  console.warn("Confirmation dialog triggered without an onConfirm handler");
};

export const useConfirmationDialog = () => {
  const [dialogState, setDialogState] = useState<InitialState>({
    intent: "info",
    message: "",
    onConfirm: defaultOnConfirm,
    open: false,
    title: "",
  });

  const openDialog = (
    title: string,
    message: string,
    intent: "info" | "success" | "error",
    onConfirm: () => void
  ) => {
    setDialogState({ intent, message, onConfirm, open: true, title });
  };

  const closeDialog = () => {
    setDialogState((prevState) => ({ ...prevState, open: false }));
  };

  return { closeDialog, dialogState, openDialog };
};
