import {
  Avatar,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const CollaboratorTableRowSkeleton = () => {
  return (
    <>
      <TableRow tabIndex={-1} role="checkbox">
        <TableCell align="center" sx={{ width: "25%" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Skeleton variant="circular" animation="wave">
              <Avatar alt={""} src={""} />
            </Skeleton>
            <Skeleton animation="wave" width={50} />
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" variant="circular">
            <IconButton size="large" color="inherit" />{" "}
          </Skeleton>
        </TableCell>
      </TableRow>
    </>
  );
};
