import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import DashboardCard from "@stories/molecules/DashboardCard/DashboardCard";
import { MultiProgressBar } from "@stories/molecules/MultiProgressBar/MultiProgressBar";
import * as RouteHelper from "@utils/routes";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TaskCountLineItem } from "permit-one-common/src/interfaces/task";
import { useNavigate } from "react-router-dom";

interface TaskSummaryProps {
  taskCounts?: TaskCountLineItem;
  project?: ProjectLineItem;
  showHeader?: boolean;
  loading: boolean;
}

const HeaderTypography = ({
  loading,
  style,
  text,
}: {
  loading: boolean;
  text: string | JSX.Element;
  style: object;
}) => (
  <>
    {loading ? (
      <Skeleton animation="wave" variant="text">
        <Typography sx={style} color="text.secondary" gutterBottom>
          {text}
        </Typography>
      </Skeleton>
    ) : (
      <Typography sx={style} color="text.secondary" gutterBottom>
        {text}
      </Typography>
    )}
  </>
);

export const TaskSummary = ({
  loading,
  project,
  showHeader,
  taskCounts,
}: TaskSummaryProps) => {
  const navigate = useNavigate();

  const assignedTasks = taskCounts?.assigned || 0;
  const inReviewTasks = taskCounts?.inReview || 0;
  const approvedTasks = taskCounts?.approved || 0;
  const notAssignedTasks = taskCounts?.notAssigned || 0;
  const allTasks = assignedTasks + inReviewTasks + approvedTasks;

  const headerStyles = {
    subtitle: {
      color: "rgb(101, 109, 118) !important",
      fontSize: "14px",
      fontWeight: 500,
      margin: "-5px 0 30px",
    },
    title: {
      color: "rgb(31, 35, 40) !important",
      fontSize: "19px",
      fontWeight: 700,
      margin: "0 0 10px",
    },
  };

  const taskCardData = [
    {
      className: "notassigned",
      count: notAssignedTasks,
      desc: "Current tasks requiring assignment",
      status: 0,
      title: "Not Assigned",
    },
    {
      className: "assigned",
      count: assignedTasks,
      desc: "Current tasks requiring action from assignee",
      status: 1,
      title: "Assigned",
    },
    {
      className: "inreview",
      count: inReviewTasks,
      desc: "Actioned and pending review by permit approver",
      status: 2,
      title: "In Review",
    },
    {
      className: "approved",
      count: approvedTasks,
      desc: "Permits approved with no further action required",
      status: 3,
      title: "Approved",
    },
  ];

  return (
    <>
      {showHeader && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "calc(100% - 538px)" }}>
            <Stack direction="row" spacing={1}>
              <HeaderTypography
                loading={loading}
                text="My Tasks"
                style={headerStyles.title}
              />
              <HeaderTypography
                loading={loading}
                text={<span className="task-number">({allTasks})</span>}
                style={headerStyles.title}
              />
            </Stack>
            <HeaderTypography
              loading={loading}
              text="Summary of My Tasks across all Project Permits and Conditions."
              style={headerStyles.subtitle}
            />
          </div>
          <MultiProgressBar
            loading={loading}
            assigned={assignedTasks}
            assignedText="Assigned"
            inReview={inReviewTasks}
            inReviewText="In Review"
            approved={approvedTasks}
            approvedText="Approved"
            notAssigned={notAssignedTasks}
            notAssignedText="Not Assigned"
          />
        </div>
      )}

      <Box
        className="dashb-cards"
        flexGrow="1"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ display: "flex", flexGrow: 1 }}
      >
        <Grid container spacing={2}>
          {taskCardData.map(({ className, count, desc, status, title }) => (
            <Grid item xs={6} md={2.4} className={className} key={status}>
              <DashboardCard
                loading={loading}
                title={title}
                subTitle={count.toString()}
                desc={desc}
                handleClick={() => {
                  if (project) {
                    navigate(
                      `${RouteHelper.tasks(project.id)}?status=${status}`
                    );
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
