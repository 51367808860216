import { Grid } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ColDef } from "ag-grid-community";
import { ArchiveProjectIcon } from "assets/constants";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { useCallback, useState } from "react";

import { Table } from "../Table/Table";
import { ActionCell, CellAction, centerCellStyles } from "../Table/TableCells";

interface CommentTable2Props {
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
  searchTerm: string;
  conditionComments: ConditionCommentLineItem[];
  deleteComment: (comment: ConditionCommentLineItem) => void;
}

export const CommentTable2 = ({
  canEdit,
  conditionComments,
  deleteComment,
  loading,
  searchTerm,
}: CommentTable2Props) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedComment, setSelectedComment] =
    useState<ConditionCommentLineItem>();

  const handleClose = useCallback(async () => {
    setOpenConfirmDialog(false);
    if (selectedComment) {
      setSelectedComment(undefined);
    }
  }, [selectedComment, openConfirmDialog]);

  const handleOpenConfirmDialog = useCallback(
    async (comment: ConditionCommentLineItem) => {
      setSelectedComment(comment);
      setOpenConfirmDialog(true);
    },
    [selectedComment, conditionComments]
  );

  const handleDelete = useCallback(async () => {
    if (selectedComment) {
      await deleteComment(selectedComment);
    }
    await handleClose();
  }, [deleteComment, selectedComment, conditionComments]);

  const colDefs = [
    {
      field: "comment",
      flex: 5,
      headerName: "Title",
      valueFormatter: (params) => params.data.comment,
    },
    {
      field: "lastName",
      flex: 2,
      headerName: "By",
      valueFormatter: (params) =>
        `${params.data.firstName} ${params.data.lastName}`,
    },
  ] as ColDef[];

  if (canEdit) {
    colDefs.push({
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    });
  }

  const cellActions: CellAction[] = [
    {
      action: handleOpenConfirmDialog,
      icon: <ArchiveProjectIcon />,
      title: "Delete Comment",
      validateEnabled: () => canEdit,
    },
  ];
  return (
    <Grid item xs={12} md={12} mt={2}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={conditionComments}
        searchValue={searchTerm}
        context={{
          cellActions,
        }}
      />
      <ConfirmationDialog
        message={"Are you sure you want to delete this comment?"}
        open={openConfirmDialog}
        title={"Delete Comment"}
        intent={"error"}
        onCancel={() => handleClose()}
        onConfirm={() => handleDelete()}
      />
    </Grid>
  );
};
