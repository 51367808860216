import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitInspectionLineItem,
  createDefaultPermitInspection,
} from "permit-one-common/src/interfaces/permitInspection";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";

import { InspectionForm } from "../InspectionForm/InspectionForm";

interface InspectionModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  createInspection: (inspections: PermitInspectionLineItem) => void;
  handleClose: () => void;
}

export default function InspectionModal({
  createInspection,
  handleClose,
  open,
  permit,
  project,
}: InspectionModalProps) {
  const onSubmit = async (inspection: PermitInspectionLineItem) => {
    await createInspection(inspection);
    handleClose();
  };
  const title = "Create Inspection";
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle
          sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 14,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px 35px 40px" }}>
          <InspectionForm
            project={project}
            permit={permit}
            inspection={createDefaultPermitInspection(project, permit)}
            handleSubmit={onSubmit}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
