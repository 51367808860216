import { uploadImage } from "@hooks/utils/useUpload";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  Avatar,
  Box,
  FormLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useFormik } from "formik";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import phone from "phone";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as yup from "yup";

interface CreateProfileFormProps {
  profile: ProfileLineItem;
  handleNext: () => void;
  setProfile: (profile: ProfileLineItem) => void;
}

export const CreateProfileForm = ({
  handleNext,
  profile,
  setProfile,
}: CreateProfileFormProps) => {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [tempLogoUrl, setTempLogoUrl] = useState(profile.photoUrl);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    jobTitle: yup.string().required("Job title is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .test("Valid Phone", "Phone not valid for country", function (item) {
        const res = phone(item, {
          country: this.parent.country,
          validateMobilePrefix: false,
        });
        return res.isValid;
      }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile.firstName,
      jobTitle: profile.jobTitle,
      lastName: profile.lastName,
      phoneNumber: profile.phoneNumber,
      photoUrl: profile.photoUrl,
    },
    onSubmit: async (values) => {
      setProfile({
        ...profile,
        firstName: values.firstName,
        jobTitle: values.jobTitle,
        lastName: values.lastName,
        originalPhotoUrl: values.photoUrl,
        phoneNumber: values.phoneNumber,
        photoUrl: tempLogoUrl,
      });
      handleNext();
    },
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);

        const fileKey = `logos/${profile.id}/${file.name}`;
        await uploadImage(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          () => {
            setUploadProgress(0);

            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          }
        );

        formik.setFieldValue("photoUrl", fileKey);
        const urlToImage = URL.createObjectURL(file);
        setTempLogoUrl(urlToImage);
        setIsUploading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        margin: "0 !important",
        padding: "40px",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{ flexGrow: 1 }}
        className="borderField"
      >
        <Grid container spacing={3}>
          <Grid item md={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              margin="dense"
              autoComplete="given-name"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName ? formik.errors.firstName : ""
              }
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              fullWidth
              margin="dense"
              autoComplete="family-name"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName ? formik.errors.lastName : ""}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="jobTitle"
              name="jobTitle"
              label="Job Title"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.jobTitle}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
              helperText={formik.touched.jobTitle ? formik.errors.jobTitle : ""}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting || isUploading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
              }
            />
          </Grid>
          <Grid
            item
            sm={8}
            sx={{
              display: "flex !important",
              justifyContent: "left !important;",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex !important",
                gap: "20px",
                justifyContent: "left !important;",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                  {isUploading ? (
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {tempLogoUrl ? (
                        <Avatar
                          alt="User 3"
                          src={tempLogoUrl}
                          sx={{ height: 64, width: 64 }}
                        />
                      ) : null}
                      <CircularProgressWithLabel value={uploadProgress} />
                      <FormLabel component="legend">Profile Picture</FormLabel>
                    </Stack>
                  ) : (
                    <Stack display="flex" alignItems="flex-start" gap="10px">
                      {tempLogoUrl ? (
                        <Avatar
                          alt="User 3"
                          src={tempLogoUrl}
                          sx={{ height: 64, width: 64 }}
                        />
                      ) : null}
                      <input
                        ref={fileInput}
                        hidden
                        accept=".jpeg,.jpg,.png,.webp"
                        type="file"
                        style={{
                          cursor: "pointer",
                          height: "100px",
                          opacity: 0,
                          padding: 0.5,
                          position: "fixed",
                          width: "100px",
                          zIndex: 1,
                        }}
                        onChange={onUpload}
                      />
                      <Tooltip
                        TransitionComponent={Zoom}
                        placement="top"
                        title="Upload"
                      >
                        <IconButton
                          color="error"
                          sx={{
                            "&:hover ": {
                              background: theme.palette.primary.dark,
                              color: theme.palette.primary.light,
                            },
                            background: theme.palette.primary.light,
                            borderColor: "black",
                            color: theme.palette.primary.dark,
                          }}
                          disabled={isUploading}
                          size="large"
                          onClick={onFileInput}
                        >
                          <UploadTwoToneIcon
                            sx={{
                              fontSize: "1.1rem",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <FormLabel component="legend" sx={{ color: "#212B36" }}>
                        Profile Picture
                      </FormLabel>
                    </Stack>
                  )}
                  {formik.touched.photoUrl &&
                  Boolean(formik.errors.photoUrl) ? (
                    <Typography
                      color="error"
                      sx={{ fontSize: "10px", margin: "0 !important" }}
                    >
                      Upload an image
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "10px", margin: "0 !important" }}
                    >
                      Image size should not exceed 10Mb.
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={false}
              type="submit"
              disabled={formik.isSubmitting}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
