import { Box } from "@mui/material";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";

interface PermitLicensesProps {
  loading: boolean;
  organisation?: OrganisationLineItem;
}

export const PermitLicenses = ({ loading }: PermitLicensesProps) => {
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0 !important",
          padding: "40px",
        }}
      >
        What is this? {loading}
      </Box>
    </>
  );
};
