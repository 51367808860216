import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  createDefaultCondition,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";

import { ConditionForm } from "../ConditionForm/ConditionForm";

interface ConditionModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  conditionCount: number;
  permitItemNumbers: Map<ConditionAuthority, number[]>;
  existingCondition?: ConditionLineItem;
  handleOpenConditionDocumentModal?: (condition: ConditionLineItem) => void;
  createCondition: (conditions: ConditionLineItem) => void;
  updateCondition: (conditions: ConditionLineItem) => void;
  handleClose: () => void;
}

export default function ConditionModal({
  conditionCount,
  createCondition,
  existingCondition,
  handleClose,
  handleOpenConditionDocumentModal,
  open,
  permit,
  permitItemNumbers,
  project,
  updateCondition,
}: ConditionModalProps) {
  const onSubmit = async (condition: ConditionLineItem) => {
    if (existingCondition) {
      await updateCondition(condition);
    } else {
      await createCondition(condition);
    }
    handleClose();
  };
  const title = existingCondition ? "Edit Condition" : "Create Condition";
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle
          sx={{ fontSize: "20px !important", padding: "30px 34px 25px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                background: "#023AF3",
              },
              background: "#011F44",
              color: "#fff",
              position: "absolute",
              right: 14,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px 20px 40px" }}>
          <ConditionForm
            permit={permit}
            editMode={!!existingCondition}
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
            permitItemNumbers={permitItemNumbers}
            condition={
              existingCondition ||
              createDefaultCondition(project, permit, conditionCount)
            }
            handleSubmit={onSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
