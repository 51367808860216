import { BasicLayout } from "@stories/layout/BasicLayout";
import MainDashboardLayout from "@stories/layout/MainLayout";
import { NoProfileLayout } from "@stories/layout/NoProfileLayout";
import HomePageLayout from "@stories/layout/ProjectsLayout";
import PublicLayout from "@stories/layout/PublicLayout";
import * as RouteHelper from "@utils/routes";
import { AcceptInviteView } from "@views/Pages/Account/AcceptInviteView";
import { CreateAccountView } from "@views/Pages/Account/CreateAccountView";
import { UserAccountView } from "@views/Pages/Account/UserAccountView";
import AllConditionsView from "@views/Pages/AllConditions/AllConditionsView";
import { ForgotPasswordView } from "@views/Pages/Authenticate/ForgotPasswordView";
import { LoginView } from "@views/Pages/Authenticate/LoginView";
import ConditionView from "@views/Pages/Condition/ConditionView";
import ListConditionsView from "@views/Pages/Condition/ListConditionsView";
import PublicConditionsView from "@views/Pages/Condition/PublicConditionsView";
import DashboardView from "@views/Pages/Dashboard/DashboardView";
import DirectoryView from "@views/Pages/Directory/DirectoryView";
import DocumentsView from "@views/Pages/Document/DocumentsView";
import GlobalDirectoryView from "@views/Pages/GlobalDirectory/GlobalDirectory";
import { ApiIntegrationView } from "@views/Pages/Home/ApiIntegrationView";
import { BlogDetail } from "@views/Pages/Home/BlogDetails";
import { BlogView } from "@views/Pages/Home/BlogView";
import { DefinitionsView } from "@views/Pages/Home/DefinitionsView";
import { DemoView } from "@views/Pages/Home/DemoView";
import { HomeView } from "@views/Pages/Home/HomeView";
import { ImplementationView } from "@views/Pages/Home/ImplementationView";
import { PartnerView } from "@views/Pages/Home/PartnerView";
import { PricingView } from "@views/Pages/Home/PricingView";
import { PrivacyPolicyView } from "@views/Pages/Home/PrivacyPolicyView";
import { ReportingAndAnalyticsView } from "@views/Pages/Home/ReportingAndAnalyticsView";
import { SmartDirectoryView } from "@views/Pages/Home/SmartDirectoryView";
import { SupportView } from "@views/Pages/Home/SupportView";
import { TermsConditionsView } from "@views/Pages/Home/TermsConditionsView";
import { WhoWeAreView } from "@views/Pages/Home/WhoWeAreView";
import PermitsView from "@views/Pages/Permit/PermitsView";
import ListProjectsView from "@views/Pages/Project/ListProjectsView";
import ProjectMailView from "@views/Pages/ProjectMail/ProjectMailView";
import TasksView from "@views/Pages/Task/TasksView";
import { UsersView } from "@views/Pages/UsersView/UsersView";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { browserStorage } from "permit-one-common/src/utils/browserStorage";

export interface RouteInfo {
  index?: boolean;
  path: string;
  component: React.FC<any>;
  layout: React.FC<any>;
}

const routesUnauthenticated: RouteInfo[] = [
  {
    component: HomeView,
    index: true,
    layout: BasicLayout,
    path: RouteHelper.home,
  },
  // {
  //   path: RouteHelper.signUp,
  //   component: SignUpView,
  //   layout: BasicLayout,
  // },
  {
    component: LoginView,
    layout: BasicLayout,
    path: RouteHelper.login,
  },
  {
    component: ForgotPasswordView,
    layout: BasicLayout,
    path: RouteHelper.forgotPassword,
  },
  {
    component: PricingView,
    layout: BasicLayout,
    path: RouteHelper.pricing,
  },
  {
    component: DemoView,
    layout: BasicLayout,
    path: RouteHelper.demo,
  },
  {
    component: PartnerView,
    layout: BasicLayout,
    path: RouteHelper.partnership,
  },
  {
    component: SmartDirectoryView,
    layout: BasicLayout,
    path: RouteHelper.smartDirectory,
  },
  {
    component: ApiIntegrationView,
    layout: BasicLayout,
    path: RouteHelper.apiIntegration,
  },
  {
    component: ImplementationView,
    layout: BasicLayout,
    path: RouteHelper.implementation,
  },
  {
    component: BlogView,
    layout: BasicLayout,
    path: RouteHelper.blog,
  },
  {
    component: BlogDetail,
    layout: BasicLayout,
    path: RouteHelper.blogDetail(":title"),
  },
  {
    component: DefinitionsView,
    layout: BasicLayout,
    path: RouteHelper.definitions,
  },
  {
    component: WhoWeAreView,
    layout: BasicLayout,
    path: RouteHelper.whoWeare,
  },
  {
    component: SupportView,
    layout: BasicLayout,
    path: RouteHelper.support,
  },
  {
    component: ReportingAndAnalyticsView,
    layout: BasicLayout,
    path: RouteHelper.reportingAndAnalytics,
  },
  {
    component: PrivacyPolicyView,
    layout: BasicLayout,
    path: RouteHelper.privacyPolicy,
  },
  {
    component: TermsConditionsView,
    layout: BasicLayout,
    path: RouteHelper.termsConditions,
  },
  {
    component: PublicConditionsView,
    layout: PublicLayout,
    path: RouteHelper.conditionPublic(":accessId"),
  },
];

const routesAuthenticatedNoProfile: RouteInfo[] = [
  {
    component: CreateAccountView,
    index: true,
    layout: NoProfileLayout,
    path: RouteHelper.createAccount,
  },
];

const routesAuthenticatedNoProfileInvite: RouteInfo[] = [
  {
    component: AcceptInviteView,
    index: true,
    layout: NoProfileLayout,
    path: RouteHelper.acceptInvite,
  },
];

const routesAuthenticated: RouteInfo[] = [
  {
    component: UserAccountView,
    layout: HomePageLayout,
    path: RouteHelper.userAccount,
  },
  {
    component: ListProjectsView,
    index: true,
    layout: HomePageLayout,
    path: RouteHelper.listProjects,
  },
  {
    component: UsersView,
    index: true,
    layout: HomePageLayout,
    path: RouteHelper.users,
  },
  {
    component: DashboardView,
    index: true,
    layout: MainDashboardLayout,
    path: RouteHelper.overview(":projectId"),
  },
  {
    component: DirectoryView,
    layout: MainDashboardLayout,
    path: RouteHelper.directory(":projectId"),
  },
  {
    component: GlobalDirectoryView,
    layout: HomePageLayout,
    path: RouteHelper.globalDirectory,
  },
  // {
  //   component: GlobalDirectoryView,
  //   layout: MainDashboardLayout,
  //   path: RouteHelper.projectGlobalDirectory(":projectId"),
  // },
  {
    component: DocumentsView,
    layout: MainDashboardLayout,
    path: RouteHelper.documents(":projectId"),
  },
  {
    component: PermitsView,
    layout: MainDashboardLayout,
    path: RouteHelper.permits(":projectId"),
  },
  {
    component: TasksView,
    layout: MainDashboardLayout,
    path: RouteHelper.tasks(":projectId"),
  },
  {
    component: AllConditionsView,
    layout: MainDashboardLayout,
    path: RouteHelper.allConditions(":projectId"),
  },
  {
    component: ListConditionsView,
    layout: MainDashboardLayout,
    path: RouteHelper.conditions(":projectId", ":permitId", ":conditionId"),
  },
  {
    component: ListConditionsView,
    layout: MainDashboardLayout,
    path: RouteHelper.conditions(":projectId", ":permitId"),
  },
  {
    component: ConditionView,
    layout: MainDashboardLayout,
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "properties"
    ),
  },

  {
    component: ConditionView,
    layout: MainDashboardLayout,
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "documents"
    ),
  },
  {
    component: ConditionView,
    layout: MainDashboardLayout,
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "collaborators"
    ),
  },
  {
    component: ConditionView,
    layout: MainDashboardLayout,
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "mail"
    ),
  },
  {
    component: ConditionView,
    layout: MainDashboardLayout,
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "event-log"
    ),
  },
  {
    component: ProjectMailView,
    layout: MainDashboardLayout,
    path: RouteHelper.inbox(":projectId"),
  },
];

export const getRoutes = (loggedIn: boolean, profile?: ProfileLineItem) => {
  if (loggedIn && !profile) {
    const isInvite = browserStorage.getItem("isInvite") === "true";
    if (isInvite) {
      return routesAuthenticatedNoProfileInvite;
    }
    return routesAuthenticatedNoProfile;
  }
  if (loggedIn) {
    return routesAuthenticated;
  }

  return routesUnauthenticated;
};
