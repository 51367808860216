import { UploadStatus, uploadImage } from "@hooks/utils/useUpload";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import {
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { QuillEditor } from "@stories/atoms/QuillEditor/QuillEditor";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SubCard from "@stories/ui-component/cards/SubCard";
import { useFormik } from "formik";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import {
  MailThreadLineItem,
  createDefaultMailThread,
} from "permit-one-common/src/interfaces/mail";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

import { fileTypeToIcon } from "../../../utils/file";
import { UploadModal } from "../BulkDocumentForm/UploadModal";

interface MailFormProps {
  loading: boolean;
  profile: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  handleSubmit: (mailThread: MailThreadLineItem) => void;
  handleClose: () => void;
}

export const MailForm = ({
  condition,
  handleSubmit,
  loading,
  permit,
  profile,
  project,
}: MailFormProps) => {
  const theme = useTheme();

  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleUpload = async () => {
    const uploadKeys = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload[i];
      if (fileToUpload) {
        setUploadProgress(0);
        if (fileToUpload) {
          const fileKey = `attachments/${uuid()}/${fileToUpload.name}`;
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
            }
          );
          setUploadedIndices([...uploadedIndice, i]);
          uploadKeys.push(fileKey);
        }
      }
      setUploadStatus(UploadStatus.Saving);
    }
    return uploadKeys;
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        setFilesToUpload(Array.from(e.currentTarget?.files));
      }
    }
  };

  const validationSchema = yup.object({
    condition_id: yup.string(),
    message: yup.string().required("Message is required"),
    permit_id: yup.string(),
    subject: yup.string().required("Subject is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      message: "",
      subject: "",
    },
    onSubmit: async (values) => {
      if (profile && project && permit && condition) {
        const uploadKeys = await handleUpload();
        const mailThread = createDefaultMailThread(
          values.subject,
          values.message,
          profile,
          uploadKeys,
          project.id,
          project.projectName,
          permit.id,
          permit.permitName,
          condition.id,
          condition.conditionName
        );

        await handleSubmit({
          ...mailThread,
        });
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <Container component="main" maxWidth="xl" sx={{ padding: "0 !important" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <StyledTextField
              loading={loading}
              id="subject"
              name="subject"
              fullWidth
              disabled={formik.isSubmitting || loading}
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              label="Subject"
            />
          </Grid>
          <Grid item xs={12} md={12} className="messageBox">
            {loading ? (
              <Skeleton animation="wave" sx={{ margin: "auto" }}>
                <SubCard
                  sx={{
                    "& .quill": {
                      "& .ql-container": {
                        "& .ql-editor": {
                          minHeight: 125,
                        },
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                        borderColor: "#000000 !important",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "0.875rem",
                      },
                      "& .ql-font-roboto": {
                        fontFamily: "Roboto, sans-serif",
                      },
                      "& .ql-toolbar": {
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? "dark.light"
                            : "grey.100",
                        borderColor: "#000000",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      },
                      "&.ql-editor p": {
                        fontFamily: "Roboto, sans-serif",
                      },
                      bgcolor:
                        theme.palette.mode === "dark" ? "dark.main" : "grey.50",
                      borderRadius: "12px",
                    },
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? "dark.dark"
                        : theme.palette.primary[200] + 40,
                    width: "100%",
                  }}
                >
                  <QuillEditor
                    loading={loading}
                    onChange={(value: string) => {
                      formik.setFieldValue("message", value);
                    }}
                    value={formik.values.message}
                  />
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Grid item>
                      <Stack direction="row">
                        <IconButton size="large" onClick={onFileInput}>
                          <input
                            hidden
                            ref={fileInput}
                            accept="*/*"
                            multiple
                            type="file"
                            onChange={handleSelectFiles}
                          />
                          <AttachmentTwoToneIcon />
                        </IconButton>
                        {filesToUpload.map((file) => {
                          return fileTypeToIcon(file.name);
                        })}
                      </Stack>
                    </Grid>
                  </Grid>
                </SubCard>
              </Skeleton>
            ) : (
              <SubCard
                sx={{
                  "& .quill": {
                    "& .ql-container": {
                      "& .ql-editor": {
                        minHeight: 125,
                      },
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                      borderColor: "#000000 !important",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "0.875rem",
                    },
                    "& .ql-font-roboto": {
                      fontFamily: "Roboto, sans-serif",
                    },
                    "& .ql-toolbar": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "dark.light"
                          : "grey.100",
                      borderColor: "#000000",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    },
                    "&.ql-editor p": {
                      fontFamily: "Roboto, sans-serif",
                    },
                    bgcolor:
                      theme.palette.mode === "dark" ? "dark.main" : "grey.50",
                    borderRadius: "12px",
                  },
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "dark.dark"
                      : theme.palette.primary[200] + 40,
                }}
              >
                <QuillEditor
                  loading={loading}
                  onChange={(value: string) => {
                    formik.setFieldValue("message", value);
                  }}
                  value={formik.values.message}
                />
                <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Stack direction="row">
                      <IconButton
                        size="large"
                        onClick={onFileInput}
                        className="attach-btn"
                      >
                        <input
                          hidden
                          ref={fileInput}
                          accept="*/*"
                          multiple
                          type="file"
                          onChange={handleSelectFiles}
                        />
                        <Stack direction="row" spacing={2}>
                          <AttachmentTwoToneIcon />
                          <Typography>Attachments</Typography>
                        </Stack>
                      </IconButton>
                      {filesToUpload.map((file) => {
                        return fileTypeToIcon(file.name);
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </SubCard>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={loading}
              type="submit"
              disabled={formik.isSubmitting}
            >
              Submit
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      <UploadModal total={uploadProgress} uploadStatus={uploadStatus} />
    </Container>
  );
};
