import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  data: BreadcrumbData[];
  loading: boolean;
  icon?: React.ReactNode;
}

interface BreadcrumbSkeletonProps {
  length: number;
  icon?: React.ReactNode;
}

export interface BreadcrumbData {
  href: string;
  label: string;
}

export function BreadcrumbsSkeleton(props: BreadcrumbSkeletonProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {props.icon ? <Skeleton variant="circular" width={20} /> : null}
      <Skeleton variant="text" width={80} />
      {props.length > 1 ? (
        <>
          <Typography variant="body2" color="textSecondary">
            /
          </Typography>
          <Skeleton variant="text" width={100} />
        </>
      ) : null}
    </Stack>
  );
}

export function Breadcrumbs(props: BreadcrumbProps) {
  if (props.loading) {
    return (
      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BreadcrumbsSkeleton length={props.data.length} icon={props.icon} />
      </Grid>
    );
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  if (props.data.length === 0) {
    return null;
  }
  const [first, ...rest] = props.data;
  const last = rest.pop();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      md={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {props.icon || null}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          aria-labelledby="with-menu-demo-breadcrumbs"
        >
          {rest.map(({ href, label }) => {
            return (
              <MenuItem onClick={handleClose} key={label}>
                <Link
                  color="textPrimary"
                  to={href}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  {label}
                </Link>
              </MenuItem>
            );
          })}
        </Menu>
        <MuiBreadcrumbs aria-label="breadcrumbs" sx={{ alignContent: "end" }}>
          <Link
            color="textPrimary"
            to={first.href}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            {first.label}
          </Link>
          {rest.length > 0 && (
            <IconButton color="primary" size="small" onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
          )}
          {last && (
            <Link
              color="textPrimary"
              to={last.href}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {last.label}
            </Link>
          )}
        </MuiBreadcrumbs>
      </Stack>
    </Grid>
  );
}
