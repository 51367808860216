import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Skeleton, styled } from "@mui/material";

export const StyledLoadingButtonBase = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.grey[100],
  px: 2.75,
  py: 1.5,
}));

export const StyledButtonBase = styled(Button)(() => ({
  "&:hover": {
    ".button-icon": {
      color: "black",
    },
    opacity: 0.9,
  },
  color: "white",
  padding: "8px 32px",
}));

type StyledButtonProps = ButtonProps & { loading: boolean };

export const StyledButton = (props: StyledButtonProps) => {
  const { loading, ...otherProps } = props;
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <StyledButtonBase {...otherProps}>{props.children}</StyledButtonBase>
      </Skeleton>
    );
  }

  return <StyledButtonBase {...otherProps}>{props.children}</StyledButtonBase>;
};

type LoadingButtonPropsExtended = LoadingButtonProps & {
  action?: () => Promise<void>;
};

export const StyledLoadingButton = (props: LoadingButtonPropsExtended) => {
  if (props.loading) {
    return (
      <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
        <LoadingButton {...props}>{props.children}</LoadingButton>
      </Skeleton>
    );
  }
  return <LoadingButton {...props}>{props.children}</LoadingButton>;
};
