import { Grid, Stack, styled } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  AvatarNameCellRenderer,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

interface DirectoryTable2Props {
  loading: boolean;
  directory: DirectoryContactLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchTerm: string;
}

export const DirectoryTable2 = ({
  directory,
  loading,
  organisation,
  profile,
  searchTerm,
}: DirectoryTable2Props) => {
  const colDefs = [
    {
      cellRenderer: AvatarNameCellRenderer,
      field: "name",
      flex: 4,
      headerName: "Name",
    },
    {
      field: "organisationName",
      flex: 3,
      headerName: "Company",
    },
    {
      field: "jobTitle",
      flex: 3,
      headerName: "Job Title",
    },
    {
      field: "email",
      flex: 3,
      headerName: "Email",
    },
    {
      field: "phoneNumber",
      flex: 3,
      headerName: "Phone",
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  return (
    <Grid item xs={12} md={12}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={directory}
            searchValue={searchTerm}
            context={{
              organisation,
              profile,
            }}
            searchKeys={[
              "name",
              "organisationName",
              "jobTitle",
              "email",
              "phoneNumber",
            ]}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
