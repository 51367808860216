import { useConditionComment } from "@hooks/crud/useConditionComment";
import { useDocument } from "@hooks/crud/useDocument";
import { uploadFile } from "@hooks/utils/useUpload";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ConditionDocumentSteps } from "@stories/molecules/ConditionDocumentSteps/ConditionDocumentSteps";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";
import { useCallback } from "react";

import { ConditionCommentForm } from "./ConditionCommentForm";
import { ConditionDocumentForm } from "./ConditionDocumentForm";
import { ConditionReviewForm } from "./ConditionReviewForm";

interface ConditionDocumentModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  userProfile: ProfileLineItem;
  canEdit: boolean;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleClose: () => void;
}

export default function ConditionDocumentModal({
  canEdit,
  condition,
  handleClose,
  handleEditCondition,
  open,
  permit,
  project,
  userProfile,
}: ConditionDocumentModalProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const {
    createDocuments,
    deleteDocument,
    documents,
    isDocumentLoading,
    isDocumentUpdating,
    updateDocuments,
  } = useDocument(project?.id, permit?.id, condition?.id);

  const {
    comments,
    createComments,
    deleteComment,
    isCommentsLoading,
    isCommentsUpdating,
  } = useConditionComment(condition?.id);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmitDocuments = async () => {
    handleNext();
  };

  const onSubmitComments = async () => {
    handleNext();
  };

  const onSendForReview = useCallback(async () => {
    await handleEditCondition({
      ...condition,
      status: ConditionStatus.InReview,
    });
    handleClose();
  }, []);

  const onAccept = useCallback(async () => {
    await handleEditCondition({
      ...condition,
      status: ConditionStatus.Approved,
    });
    handleClose();
  }, []);

  const onReject = useCallback(async (rejectMessage: string) => {
    const rejectionMessage = `<p style='color:red;'>${rejectMessage}</p>`;
    await handleEditCondition({
      ...condition,
      conditionComments: condition.conditionComments + rejectionMessage,
      status: ConditionStatus.Rejected,
    });
    handleClose();
  }, []);

  const loading = isDocumentLoading || isCommentsLoading;
  const disabled = isDocumentUpdating || isCommentsUpdating;
  const selectedDocumentStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <ConditionDocumentForm
            canEdit={canEdit}
            loading={loading}
            disabled={disabled}
            project={project}
            permit={permit}
            condition={condition}
            userProfile={userProfile}
            documents={documents}
            createDocuments={createDocuments}
            updateDocuments={updateDocuments}
            deleteDocument={deleteDocument}
            handleSubmit={onSubmitDocuments}
            handleClose={handleClose}
            uploadFile={uploadFile}
            documentCount={documents.length}
          />
        );
      case 1:
        return (
          <ConditionCommentForm
            canEdit={canEdit}
            loading={loading}
            disabled={disabled}
            project={project}
            permit={permit}
            condition={condition}
            userProfile={userProfile}
            comments={comments}
            createComments={createComments}
            deleteComment={deleteComment}
            handleSubmit={onSubmitComments}
            handleBack={handleBack}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <ConditionReviewForm
            canEdit={canEdit}
            handleBack={handleBack}
            handleClose={handleClose}
            onSendForReview={onSendForReview}
            onReject={onReject}
            onAccept={onAccept}
            userProfile={userProfile}
            project={project}
            permit={permit}
            condition={condition}
            comments={comments}
            documents={documents}
          />
        );
    }
  })();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            "&:hover": {
              background: "#005cd1",
            },
            background: "#000",
            color: "#fff",
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <ConditionDocumentSteps activeStep={activeStep} loading={loading} />
        {selectedDocumentStep}
      </DialogContent>
    </Dialog>
  );
}
