import { CognitoUser } from "@aws-amplify/auth";
import { useProfile } from "@hooks/crud/useProfile";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { Auth } from "aws-amplify";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { UserLineItem } from "permit-one-common/src/interfaces/user";
import { sleep } from "permit-one-common/src/utils/sleep";
import * as React from "react";

type AuthResponse = {
  authenticated: boolean;
  error?: string;
};

export enum AuthenticateChallenge {
  NONE = "NONE",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  AUTH_ERROR = "AUTH_ERROR",
  PROFILE_ERROR = "PROFILE_ERROR",
}

// We shouldn't have to do this, see this issue for details: https://github.com/aws-amplify/amplify-js/issues/3733
type AuthUser = CognitoUser & {
  challengeName?: AuthenticateChallenge;
  preferredMFA?: AuthenticateChallenge;
};

type AC = {
  loggedIn: boolean;
  isFirstLoad: boolean;
  user?: UserLineItem;
  isAuthLoading: boolean;
  userProfile?: ProfileLineItem;
  setUserProfile: (profile: ProfileLineItem) => void;
  isAuthenticated: () => Promise<boolean>;
  signIn: (
    username: string,
    password: string
  ) => Promise<AuthenticateChallenge>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<string>;
  register: (email: string) => Promise<AuthResponse>;
  resetPassword: (
    username: string,
    oldPassword: string,
    newPassword: string
  ) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<void>;
};

export const AuthContext = React.createContext<AC>({
  changePassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(""),
  isAuthLoading: false,
  isAuthenticated: () => Promise.resolve(false),
  isFirstLoad: false,
  loggedIn: false,
  register: () => Promise.resolve({ authenticated: false }),
  resetPassword: () => Promise.resolve(),
  setUserProfile: () => {
    return;
  },
  signIn: () => Promise.resolve(AuthenticateChallenge.NONE),
  signOut: () => Promise.resolve(),
  user: undefined,
  userProfile: undefined,
});

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = (props: AuthProviderProps) => {
  const [user, setUser] = React.useState<UserLineItem>();

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const { getProfile } = useProfile();

  const [userProfile, setUserProfile] = React.useState<ProfileLineItem>();

  const getUserId = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    const subId = payload["sub"];
    if (!subId) {
      throw new Error("User sub id not available");
    }
    return subId;
  };

  const isAuthenticated = async () => {
    const maxRetries = 3,
      delay = 2000;
    let attempt = 0;
    try {
      setIsAuthLoading(true);
      while (attempt < maxRetries) {
        try {
          const res = await Auth.currentAuthenticatedUser();
          const userId = res.attributes.sub;
          const email = res.attributes.email || "";
          setUser({ email, id: userId });
          const profile = await getProfile(userId);
          if (!profile.success) {
            return false;
          }
          if (profile.profile) {
            setUserProfile(profile.profile);
            return true;
          }
          return true;
        } catch (error) {
          console.log(`Attempt ${attempt + 1} failed:`, error);
          attempt++;

          if (attempt < maxRetries) {
            console.log(`Retrying in ${delay} ms...`);
            await sleep(delay); // Wait before retrying
          } else {
            console.error("Max retries reached. Authentication failed.");
            return false; // All attempts failed
          }
        }
      }
    } catch (e) {
      console.error("Error checking authentication:", e);
    } finally {
      setIsAuthLoading(false); // Stop loading after all attempts
    }
    return false;
  };

  const register = async (email: string) => {
    try {
      setIsAuthLoading(true);
      const res = await Auth.signUp({
        attributes: { email },
        password: `password${Math.random().toString().slice(0, 8)}`,
        username: email,
      });
      console.log(res);
      setIsAuthLoading(false);
      return { authenticated: true };
    } catch (error: any) {
      console.log(error);
      setIsAuthLoading(false);
      return { authenticated: false };
    }
  };

  const signIn = async (
    username: string,
    password: string
  ): Promise<AuthenticateChallenge> => {
    try {
      const res = await Auth.signIn(username, password);
      const challengeName = res.challengeName || AuthenticateChallenge.NONE;
      if (challengeName === AuthenticateChallenge.NONE) {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.signInUserSession.idToken.payload.email;
        const userId = getUserId(user);
        setUser({ email, id: userId });
        try {
          const profile = await getProfile(userId);
          if (!profile.success) {
            return AuthenticateChallenge.PROFILE_ERROR;
          }
          if (profile.profile) {
            setUserProfile(profile.profile);
          }
          if (profile.success) {
            setLoggedIn(true);
          }
        } catch (e) {
          return AuthenticateChallenge.PROFILE_ERROR;
        }
      }
      return challengeName;
    } catch (e) {
      console.log(e);
      return AuthenticateChallenge.AUTH_ERROR;
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (e) {
      console.log(e);
    }
  };

  const resetPassword = async (
    username: string,
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    try {
      const user = await Auth.signIn(username, oldPassword);
      await Auth.completeNewPassword(user, newPassword);
      const res = await Auth.currentAuthenticatedUser();
      const email = res.signInUserSession.idToken.payload.email;
      const userId = getUserId(res);
      setUser({ email, id: userId });
      const profile = await getProfile(userId);
      if (profile.profile && profile.success) {
        setUserProfile(profile.profile);
      }
      if (profile.success) {
        setLoggedIn(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const forgotPassword = async (username: string): Promise<void> => {
    try {
      await Auth.forgotPassword(username);
    } catch (e) {
      console.log(e);
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<string> => {
    try {
      const res = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return res;
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const signOut = React.useCallback(async () => {
    try {
      setIsAuthLoading(true);
      await Auth.signOut();
      setLoggedIn(false);
      setUser(undefined);
      setUserProfile(undefined);
      setIsAuthLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsAuthLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (isFirstLoad) {
      isAuthenticated()
        .then((res) => {
          setLoggedIn(res);
          setIsAuthLoading(false);
        })
        .finally(() => {
          setIsFirstLoad(false);
        });
    }
  }, []);

  if (isFirstLoad) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        changePassword,
        forgotPassword,
        forgotPasswordSubmit,
        isAuthLoading,
        isAuthenticated,
        isFirstLoad,
        loggedIn,
        register,
        resetPassword,
        setUserProfile,
        signIn,
        signOut,
        user,
        userProfile,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => React.useContext(AuthContext);

export { AuthProvider, useAuthContext };
