import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Skeleton, styled } from "@mui/material";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Error = "error",
}

export const StyledButtonBase = styled(Button)<{
  btntype?: ButtonType;
}>(({ btntype }) => {
  const styles = {
    [ButtonType.Error]: {
      backgroundColor: "red",
      border: "none",
      color: "white",
      hoverBg: "red",
    },
    [ButtonType.Primary]: {
      backgroundColor: "black",
      border: "none",
      color: "white",
      hoverBg: "black",
    },
    [ButtonType.Secondary]: {
      backgroundColor: "white",
      border: "1px solid black",
      color: "black",
      hoverBg: "white",
    },
    [ButtonType.Success]: {
      backgroundColor: "green",
      border: "none",
      color: "white",
      hoverBg: "green",
    },
    default: {
      backgroundColor: "gray",
      border: "none",
      color: "white",
      hoverBg: "gray",
    },
  };

  const { backgroundColor, border, color, hoverBg } =
    styles[btntype ?? "default"];

  return {
    ":disabled": {
      cursor: "not-allowed",
      opacity: 0.3,
    },
    ":hover": {
      backgroundColor: hoverBg,
      boxShadow: "0 1px 10px gray",
    },
    backgroundColor,
    border,
    color,
    padding: "8px 32px",
    transition: "box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out",
  };
});

type StyledButtonProps = ButtonProps & {
  loading: boolean;
  btnType?: ButtonType;
};

export const StyledButton = (props: StyledButtonProps) => {
  const { btnType = ButtonType.Primary, loading, ...otherProps } = props;

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <StyledButtonBase {...otherProps}>{props.children}</StyledButtonBase>
      </Skeleton>
    );
  }

  return (
    <StyledButtonBase btntype={btnType} {...otherProps}>
      {props.children}
    </StyledButtonBase>
  );
};

type LoadingButtonPropsExtended = LoadingButtonProps & {
  action?: () => Promise<void>;
};

export const StyledLoadingButton = (props: LoadingButtonPropsExtended) => {
  if (props.loading) {
    return (
      <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
        <LoadingButton {...props}>{props.children}</LoadingButton>
      </Skeleton>
    );
  }
  return <LoadingButton {...props}>{props.children}</LoadingButton>;
};
