import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePermitCount } from "@hooks/crud/usePermitCount";
import { useTaskCount } from "@hooks/crud/useTaskCount";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box } from "@mui/material";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import ProjectModal from "@stories/organisms/ProjectModal/ProjectModal";
import { PermitSummary } from "@stories/organisms/Summary/PermitSummary";
import { TaskSummary } from "@stories/organisms/Summary/TaskSummary";
import { useState } from "react";

export const Dashboard = (): JSX.Element => {
  const { isProjectLoading, selectedProject, updateProject } =
    useProjectContext();
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { isPermitCountLoading, permitCounts } = usePermitCount(
    selectedProject?.id
  );

  const { isTaskCountLoading, taskCounts } = useTaskCount(selectedProject?.id);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const loading =
    isProjectLoading ||
    isAuthProfileLoading ||
    isPermitCountLoading ||
    isTaskCountLoading;

  const buttons = [
    {
      action: handleOpen,
      icon: <SettingsIcon fontSize="small" />,
      text: "Project Settings",
      type: HeaderButtonType.PRIMARY,
      width: 160,
    },
  ] as HeaderButtonConfig[];

  return (
    <>
      <PageContainer>
        <Header
          loading={loading}
          subTitle={
            `View overall status of permits and conditions for ${selectedProject?.projectName}` ||
            "Project Overview"
          }
          buttons={buttons}
          mainTitle={"Project Overview"}
        />
      </PageContainer>

      <Box sx={{ padding: "10px 30px 20px" }}>
        <Box
          sx={{
            background: "#F6F8FA",
            border: "1px solid rgb(208, 215, 222)",
            borderRadius: "4px",
            marginBottom: "20px",
            padding: "20px",
            width: "100%",
          }}
        >
          <TaskSummary
            taskCounts={taskCounts}
            project={selectedProject}
            showHeader
            loading={loading}
          />
        </Box>
        <Box
          sx={{
            background: "#F6F8FA",
            border: "1px solid rgb(208, 215, 222)",
            borderRadius: "4px",
            marginBottom: "20px",
            padding: "20px",
          }}
        >
          <PermitSummary
            permitCounts={permitCounts}
            project={selectedProject}
            loading={loading}
            showHeader
          />
        </Box>
      </Box>

      {open && selectedProject && userOrganisation ? (
        <ProjectModal
          open={open}
          organisation={userOrganisation}
          handleClose={handleClose}
          createProject={() => {
            console.log("Not implemeted");
          }}
          updateProject={updateProject}
          existingProject={selectedProject}
        />
      ) : null}
    </>
  );
};
