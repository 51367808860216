import useConfig from "@hooks/utils/useConfig";
import { AppBar, Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import LAYOUT_CONST, { drawerWidth } from "constant";
import { useParams } from "react-router-dom";

import "../MainLayout/dashboard.css";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface MainStyleProps {
  theme: Theme;
  open: boolean;
  layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ layout, open, theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 88,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: 88,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.sharp,
      }),
    }),
    ...(open && {
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 88,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      },
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  })
);

interface PublciLayoutProps {
  children: React.ReactNode;
}
const PublicLayout = ({ children }: PublciLayoutProps) => {
  const { accessId } = useParams();
  if (!accessId) {
    throw Error("Access id is required");
  }
  const theme = useTheme();

  const { container, layout } = useConfig();

  const header = (
    <Toolbar sx={{ padding: "0" }}>
      <Header
        isDrawOpen
        setIsDrawOpen={function (_: boolean): void {
          throw new Error("Function not implemented.");
        }}
      />
    </Toolbar>
  );

  return (
    <Box sx={{ display: "flex" }} className="dashboardPage">
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: theme.palette.background.default }}
      >
        {header}
      </AppBar>

      <div className="normal">
        {layout === LAYOUT_CONST.VERTICAL_LAYOUT && (
          <Sidebar
            accessId={accessId}
            isDrawOpen
            selectedItem={[]}
            setIsDrawOpen={function (_: boolean): void {
              throw new Error("Function not implemented.");
            }}
            setSelectedItem={function (_: string[]): void {
              throw new Error("Function not implemented.");
            }}
          />
        )}
      </div>

      <Main theme={theme} open layout={layout}>
        <Container
          maxWidth={container ? "lg" : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
        >
          {children}
        </Container>
      </Main>
    </Box>
  );
};

export default PublicLayout;
