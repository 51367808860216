import { Grid } from "@mui/material";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";

import { Table } from "../Table/Table";

interface ReadOnlyCommentTable2Props {
  loading: boolean;
  searchTerm: string;
  conditionComments: ConditionCommentLineItem[];
}

export const ReadOnlyCommentTable2 = ({
  conditionComments,
  loading,
  searchTerm,
}: ReadOnlyCommentTable2Props) => {
  const colDefs = [
    {
      field: "comment",
      flex: 3,
      headerName: "Comment",
      valueFormatter: (params) => params.data.comment,
    },
    {
      field: "createdByName",
      flex: 3,
      headerName: "By",
      valueFormatter: (params) =>
        `${params.data.firstName} ${params.data.lastName}`,
    },
    {
      field: "created",
      flex: 2,
      headerName: "Created",
      valueFormatter: (params) => {
        return params.data.created.toLocaleDateString();
      },
    },
  ] as (ColDef | ColGroupDef)[];

  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={conditionComments}
        searchValue={searchTerm}
        searchKeys={["comment", "firstName", "lastName"]}
      />
    </Grid>
  );
};
