const primaryColour = "white";
const secondaryColour = "#414651";

interface IconProps {
  variant?: "priamry" | "secondary";
  strokeWith?: number;
  width?: number;
  height?: number;
}

export const Arrow = () => (
  <svg
    width="7"
    height="8"
    viewBox="0 0 7 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.833252 6.5L5.83325 1.5M5.83325 1.5H0.833252M5.83325 1.5V6.5"
      stroke="#17B26A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MenuIcon = () => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99996 8.83337C2.4602 8.83337 2.83329 8.46028 2.83329 8.00004C2.83329 7.5398 2.4602 7.16671 1.99996 7.16671C1.53972 7.16671 1.16663 7.5398 1.16663 8.00004C1.16663 8.46028 1.53972 8.83337 1.99996 8.83337Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99996 3.00004C2.4602 3.00004 2.83329 2.62694 2.83329 2.16671C2.83329 1.70647 2.4602 1.33337 1.99996 1.33337C1.53972 1.33337 1.16663 1.70647 1.16663 2.16671C1.16663 2.62694 1.53972 3.00004 1.99996 3.00004Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99996 14.6667C2.4602 14.6667 2.83329 14.2936 2.83329 13.8334C2.83329 13.3731 2.4602 13 1.99996 13C1.53972 13 1.16663 13.3731 1.16663 13.8334C1.16663 14.2936 1.53972 14.6667 1.99996 14.6667Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CalendarIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 8.33335H2.5M13.3333 1.66669V5.00002M6.66667 1.66669V5.00002M6.5 18.3334H13.5C14.9001 18.3334 15.6002 18.3334 16.135 18.0609C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4336 17.5 15.7335 17.5 14.3334V7.33335C17.5 5.93322 17.5 5.23316 17.2275 4.69838C16.9878 4.22797 16.6054 3.84552 16.135 3.60584C15.6002 3.33335 14.9001 3.33335 13.5 3.33335H6.5C5.09987 3.33335 4.3998 3.33335 3.86502 3.60584C3.39462 3.84552 3.01217 4.22797 2.77248 4.69838C2.5 5.23316 2.5 5.93322 2.5 7.33335V14.3334C2.5 15.7335 2.5 16.4336 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0609C4.3998 18.3334 5.09987 18.3334 6.5 18.3334Z"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CloseIconV2 = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 5L5 15M5 5L15 15"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CloseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 5L5 15M5 5L15 15"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CheckboxIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
      stroke="#D5D7DA"
    />
  </svg>
);
export const CheckedboxIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
      fill="#0055C6"
    />
    <path
      d="M12 5L6.5 10.5L4 8"
      stroke={props.variant ? secondaryColour : primaryColour}
      strokeWidth="1.6666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const FilterIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowBackIosNewIcon = (props: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8334 6.99996H1.16669M1.16669 6.99996L7.00002 12.8333M1.16669 6.99996L7.00002 1.16663"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowForwardIosIcon = (props: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16663 6.99996H12.8333M12.8333 6.99996L6.99996 1.16663M12.8333 6.99996L6.99996 12.8333"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const BellIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79514 17.5001C8.38275 18.0187 9.15462 18.3334 10 18.3334C10.8454 18.3334 11.6172 18.0187 12.2048 17.5001M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5978 2.19353 11.3261 1.66675 10 1.66675C8.67391 1.66675 7.40214 2.19353 6.46446 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 9.2419 4.35039 11.005 3.62472 12.1713C3.0126 13.155 2.70654 13.6468 2.71777 13.784C2.73019 13.936 2.76238 13.9939 2.88481 14.0847C2.99538 14.1667 3.49382 14.1667 4.49071 14.1667H15.5093C16.5062 14.1667 17.0046 14.1667 17.1152 14.0847C17.2376 13.9939 17.2698 13.936 17.2822 13.784C17.2934 13.6468 16.9874 13.155 16.3753 12.1713C15.6496 11.005 15 9.2419 15 6.66675Z"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
      stroke="#717680"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const SearchButtonIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowBreadcrumIcon = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9L5 5L1 1"
      stroke="#D5D7DA"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CurrencyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2037_1775)">
      <path
        d="M7.08333 12.2222C7.08333 13.2961 7.95389 14.1666 9.02778 14.1666H10.8333C11.9839 14.1666 12.9167 13.2339 12.9167 12.0833C12.9167 10.9327 11.9839 9.99996 10.8333 9.99996H9.16666C8.01607 9.99996 7.08333 9.06722 7.08333 7.91663C7.08333 6.76603 8.01607 5.83329 9.16666 5.83329H10.9722C12.0461 5.83329 12.9167 6.70385 12.9167 7.77774M10 4.58329V5.83329M10 14.1666V15.4166M18.3333 9.99996C18.3333 14.6023 14.6024 18.3333 10 18.3333C5.39762 18.3333 1.66666 14.6023 1.66666 9.99996C1.66666 5.39759 5.39762 1.66663 10 1.66663C14.6024 1.66663 18.3333 5.39759 18.3333 9.99996Z"
        stroke="#717680"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2037_1775">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ExportIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const AddIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 4.16666V15.8333M4.16663 9.99999H15.8333"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 13.1974C16.2132 13.8069 17.2534 14.785 18.0127 16.008C18.163 16.2502 18.2382 16.3713 18.2642 16.539C18.317 16.8798 18.084 17.2988 17.7666 17.4336C17.6104 17.5 17.4347 17.5 17.0833 17.5M13.3333 9.6102C14.5681 8.99657 15.4167 7.72238 15.4167 6.25C15.4167 4.77762 14.5681 3.50343 13.3333 2.8898M11.6667 6.25C11.6667 8.32107 9.98772 10 7.91665 10C5.84559 10 4.16665 8.32107 4.16665 6.25C4.16665 4.17893 5.84559 2.5 7.91665 2.5C9.98772 2.5 11.6667 4.17893 11.6667 6.25ZM2.13268 15.782C3.46127 13.7871 5.5578 12.5 7.91665 12.5C10.2755 12.5 12.372 13.7871 13.7006 15.782C13.9917 16.219 14.1372 16.4375 14.1205 16.7166C14.1074 16.9339 13.9649 17.2 13.7913 17.3313C13.5683 17.5 13.2615 17.5 12.648 17.5H3.18528C2.5718 17.5 2.26505 17.5 2.04202 17.3313C1.86836 17.2 1.72589 16.9339 1.71285 16.7166C1.69609 16.4375 1.84162 16.219 2.13268 15.782Z"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ViewProjectIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_68_130853)">
      <path
        d="M9.33329 7.33334H5.33329M6.66663 10H5.33329M10.6666 4.66668H5.33329M13.3333 7.00001V4.53334C13.3333 3.41324 13.3333 2.85319 13.1153 2.42536C12.9236 2.04904 12.6176 1.74308 12.2413 1.55133C11.8134 1.33334 11.2534 1.33334 10.1333 1.33334H5.86663C4.74652 1.33334 4.18647 1.33334 3.75864 1.55133C3.38232 1.74308 3.07636 2.04904 2.88461 2.42536C2.66663 2.85319 2.66663 3.41324 2.66663 4.53334V11.4667C2.66663 12.5868 2.66663 13.1468 2.88461 13.5747C3.07636 13.951 3.38232 14.2569 3.75864 14.4487C4.18647 14.6667 4.74652 14.6667 5.86663 14.6667H7.66663M14.6666 14.6667L13.6666 13.6667M14.3333 12C14.3333 13.2887 13.2886 14.3333 12 14.3333C10.7113 14.3333 9.66663 13.2887 9.66663 12C9.66663 10.7113 10.7113 9.66668 12 9.66668C13.2886 9.66668 14.3333 10.7113 14.3333 12Z"
        stroke="#181D27"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_130853">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EditProjectIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_68_130857)">
      <path
        d="M7.33337 2.66669H4.53337C3.41327 2.66669 2.85322 2.66669 2.42539 2.88467C2.04907 3.07642 1.74311 3.38238 1.55136 3.75871C1.33337 4.18653 1.33337 4.74658 1.33337 5.86669V11.4667C1.33337 12.5868 1.33337 13.1468 1.55136 13.5747C1.74311 13.951 2.04907 14.257 2.42539 14.4487C2.85322 14.6667 3.41327 14.6667 4.53337 14.6667H10.1334C11.2535 14.6667 11.8135 14.6667 12.2414 14.4487C12.6177 14.257 12.9236 13.951 13.1154 13.5747C13.3334 13.1468 13.3334 12.5868 13.3334 11.4667V8.66669M5.33336 10.6667H6.44972C6.77584 10.6667 6.9389 10.6667 7.09235 10.6298C7.2284 10.5972 7.35846 10.5433 7.47775 10.4702C7.61231 10.3878 7.72761 10.2724 7.95821 10.0418L14.3334 3.66669C14.8857 3.1144 14.8857 2.21897 14.3334 1.66669C13.7811 1.1144 12.8857 1.1144 12.3334 1.66668L5.9582 8.04185C5.72759 8.27245 5.61229 8.38775 5.52984 8.52231C5.45673 8.6416 5.40286 8.77166 5.3702 8.90771C5.33336 9.06116 5.33336 9.22422 5.33336 9.55034V10.6667Z"
        stroke="#181D27"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_130857">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ArchiveProjectIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6L10 10M10 6L6 10M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.07989 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.07989 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.07989 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.07989 14 5.2 14Z"
      stroke="#181D27"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DropdownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10C10.8333 9.53977 10.4602 9.16668 9.99996 9.16668C9.53972 9.16668 9.16663 9.53977 9.16663 10C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
      stroke="#A4A7AE"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ImportIcon = (props: IconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 9V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V9M12.3333 4.83333L9 1.5M9 1.5L5.66667 4.83333M9 1.5V11.5"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddEmployeeIcon = (props: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 8.33334V3.33334M13.3333 5.83334H18.3333M13.3333 17.5V16.5C13.3333 15.0999 13.3333 14.3998 13.0608 13.865C12.8211 13.3946 12.4387 13.0122 11.9683 12.7725C11.4335 12.5 10.7334 12.5 9.33329 12.5H5.66663C4.26649 12.5 3.56643 12.5 3.03165 12.7725C2.56124 13.0122 2.17879 13.3946 1.93911 13.865C1.66663 14.3998 1.66663 15.0999 1.66663 16.5V17.5M10.4166 6.25001C10.4166 7.86084 9.11079 9.16668 7.49996 9.16668C5.88913 9.16668 4.58329 7.86084 4.58329 6.25001C4.58329 4.63918 5.88913 3.33334 7.49996 3.33334C9.11079 3.33334 10.4166 4.63918 10.4166 6.25001Z"
      stroke={props.variant ? primaryColour : secondaryColour}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
    />
  </svg>
);

export const DropdownImportIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V8M10.6667 4.66667L8 2M8 2L5.33333 4.66667M8 2V10"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const DropdownExportIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 9V9.8C13 10.9201 13 11.4802 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.4802 13 10.9201 13 9.8 13H4.2C3.07989 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V9M10.3333 5.66667L7 9M7 9L3.66667 5.66667M7 9V1"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ConfirmationIcon = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.75 54L47.25 67.5L74.25 40.5M99 54C99 78.8528 78.8528 99 54 99C29.1472 99 9 78.8528 9 54C9 29.1472 29.1472 9 54 9C78.8528 9 99 29.1472 99 54Z"
      stroke="#004EEB"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const AcceptIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99992 8.00004L6.99992 10L10.9999 6.00004M14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RejectIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99992 6.00004L5.99992 10M5.99992 6.00004L9.99992 10M14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendReminderIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.23621 14C5.7063 14.4149 6.3238 14.6667 7.00009 14.6667C7.67639 14.6667 8.29389 14.4149 8.76398 14M11.0001 5.33337C11.0001 4.27251 10.5787 3.25509 9.82852 2.50495C9.07838 1.7548 8.06096 1.33337 7.00009 1.33337C5.93923 1.33337 4.92181 1.7548 4.17167 2.50495C3.42152 3.25509 3.00009 4.27251 3.00009 5.33337C3.00009 7.3935 2.48041 8.80401 1.89987 9.73698C1.41018 10.524 1.16533 10.9174 1.17431 11.0272C1.18425 11.1488 1.21 11.1951 1.30794 11.2678C1.3964 11.3334 1.79515 11.3334 2.59266 11.3334H11.4075C12.205 11.3334 12.6038 11.3334 12.6922 11.2678C12.7902 11.1951 12.8159 11.1488 12.8259 11.0272C12.8349 10.9174 12.59 10.524 12.1003 9.73698C11.5198 8.80401 11.0001 7.3935 11.0001 5.33337Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
