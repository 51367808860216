import { useAllFiles } from "@hooks/crud/useAllFiles";
import { useExcel } from "@hooks/crud/useExcel";
import { usePdf } from "@hooks/crud/usePdf";
import { useConfirmationDialog } from "@hooks/utils/useConformationDialog";
// import FormatBoldIcon from "@mui/icons-material/FormatBold";
// import FormatItalicIcon from "@mui/icons-material/FormatItalic";
// import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  Chip,
  Grid,
  SpeedDial,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ConditionExport from "@stories/molecules/ConditionExport/ConditionExport";
import ConditionImport from "@stories/molecules/ConditionImport/ConditionImport";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CheckboxCell,
  CellAction,
  defaultCellStyles,
  RichTextInputCell,
  HeaderCheckboxCell,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  AddIcon,
  ArchiveProjectIcon,
  EditProjectIcon,
  ExportIcon,
  ImportIcon,
  ReloadIcon,
} from "assets/constants";
import {
  ConditionAuthority,
  conditonNameToString,
} from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useEffect, useState } from "react";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    left: theme.spacing(2),
    top: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  position: "absolute",
}));

interface ConditionTable2Props {
  loading: boolean;
  conditions: ConditionLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  disabled?: boolean;
  permit?: PermitLineItem;
  handleOpenConditionDocumentModal: (condition: ConditionLineItem) => void;
  handleConditionSave: (condition: ConditionLineItem) => Promise<void>;
  handleEditCondition: (condition?: ConditionLineItem) => Promise<void>;
  handleDeleteCondition: (condition: ConditionLineItem[]) => Promise<void>;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleMarkAsNotApplicable: (condition: ConditionLineItem) => Promise<void>;
}

export const ConditionTable2 = ({
  conditions,
  disabled,
  handleConditionSave,
  handleDeleteCondition,
  handleEditCondition,
  handleImportConditons,
  handleMarkAsNotApplicable,
  handleOpenConditionDocumentModal,
  loading,
  organisation,
  permit,
  profile,
  project,
}: ConditionTable2Props) => {
  const { closeDialog, dialogState, openDialog } = useConfirmationDialog();
  const { PDFDownloadProgress, downloadStepPDF, getPdfReport } = usePdf();
  const { downloadStepExcel, excelDownloadProgress, getExcelReport } =
    useExcel();
  const { allFilesDownloadProgress, downloadStepAllFiles, getFullReportInfo } =
    useAllFiles();

  const isProjectOwner =
    organisation && project && project.owner?.id === organisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);
  const canEdit = isProjectOwner || isPermitManager;
  const [locked, setLocked] = useState(true);

  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const [selectedConditionsBCA, setSelectedConditionsBCA] = useState<
    ConditionLineItem[]
  >([]);

  const [selectedConditionsDA, setSelectedConditionsDA] = useState<
    ConditionLineItem[]
  >([]);

  const handleDeleteAll = useCallback(() => {
    openDialog(
      "Delete Selected Conditions",
      "Are you sure you want to delete the selected conditions?",
      "error",
      async () => {
        try {
          await handleDeleteCondition([
            ...selectedConditionsBCA,
            ...selectedConditionsDA,
          ]);
          setSelectedConditionsDA([]);
          setSelectedConditionsBCA([]);
        } catch (error) {
          console.error("Error deleting conditions:", error);
        }
      }
    );
  }, [conditions, selectedConditionsBCA, selectedConditionsDA]);

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const [tabIndex, setTabIndex] = useState<number>(parseInt(status || "0"));
  const [searchTerm, setSearchTerm] = useState("");

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
    setSelectedConditionsBCA([]);
    setSelectedConditionsDA([]);
  };

  const handleOpenImportModel = useCallback(
    (flag: boolean) => {
      setImportModalOpen(flag);
    },
    [importModalOpen]
  );

  const handleOpenExportModal = useCallback(
    (flag: boolean) => {
      setExportModalOpen(flag);
    },
    [importModalOpen]
  );

  const handleConfirm = async () => {
    await dialogState.onConfirm();
    closeDialog();
  };

  const handleSelectAllBCA = useCallback(async () => {
    setSelectedConditionsBCA(
      selectedConditionsBCA.length
        ? conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.BCA
          )
        : []
    );
  }, [conditions, selectedConditionsBCA]);

  const handleSelectAllDA = useCallback(async () => {
    setSelectedConditionsDA(
      selectedConditionsDA.length
        ? conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.DA
          )
        : []
    );
  }, [conditions, selectedConditionsDA]);

  const handleCheck = useCallback(
    (condition: ConditionLineItem) => {
      const checked =
        !selectedConditionsBCA.includes(condition) &&
        !selectedConditionsDA.includes(condition);
      if (condition.conditionAuthority === ConditionAuthority.BCA) {
        if (checked) {
          setSelectedConditionsBCA([...selectedConditionsBCA, condition]);
        } else {
          setSelectedConditionsBCA(
            selectedConditionsBCA.filter((c) => c.id !== condition.id)
          );
        }
      } else {
        if (checked) {
          setSelectedConditionsDA([...selectedConditionsDA, condition]);
        } else {
          setSelectedConditionsDA(
            selectedConditionsDA.filter((c) => c.id !== condition.id)
          );
        }
      }
    },
    [selectedConditionsBCA, selectedConditionsDA]
  );

  const [colDefs] = useState<ColDef[]>([
    {
      autoHeight: true,
      cellClass: "center-ag-cell",
      cellRenderer: CheckboxCell,
      cellStyle: centerCellStyles,
      field: "checkbox",
      flex: 2,
      headerClass: "centered-table-header",
      headerComponent: HeaderCheckboxCell, // Use custom header
      headerName: "",
    },
    {
      autoHeight: true,
      field: "permitItemNumber",
      flex: 2,
      headerName: "Item",
      valueFormatter: (params: any) => {
        return conditonNameToString(
          params.data.conditionAuthority,
          params.data.permitItemNumber
        );
      },
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "conditionDetails",
      },
      cellStyle: {
        ...defaultCellStyles,
        alignItems: "start",
        padding: "0px",
      },
      field: "conditionDetails",
      flex: 5,
      headerComponent: () => (
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Typography
            sx={{
              color: "#71767f",
              fontFamily: "Inter, sans-serif",
              fontSize: "13px",
              fontWeight: "600",
              lineHeight: "18px",
            }}
          >
            Details
          </Typography>
          {locked && (
            <Box sx={{ transform: "scale(0.7)" }}>
              <LockIcon />
            </Box>
          )}
        </Stack>
      ),
      headerName: "Comments",
      headerStyle: { backgroundColor: "white !important" },
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "discipline",
      },
      cellStyle: {
        ...defaultCellStyles,
        alignItems: "start",
        padding: "0px",
      },
      field: "discipline",
      flex: 5,
      headerComponent: () => (
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Typography
            sx={{
              color: "#71767f",
              fontFamily: "Inter, sans-serif",
              fontSize: "13px",
              fontWeight: "600",
              lineHeight: "18px",
            }}
          >
            Discipline
          </Typography>
          {locked && (
            <Box sx={{ transform: "scale(0.7)" }}>
              <LockIcon />
            </Box>
          )}
        </Stack>
      ),
      headerName: "Discipline",
    },
    {
      autoHeight: true,
      cellRenderer: RichTextInputCell,
      cellRendererParams: {
        paramKey: "conditionComments",
      },
      cellStyle: {
        ...defaultCellStyles,
        alignItems: "start",
        display: "flex !important",
        padding: "0px 8px",
      },
      field: "conditionComments",
      flex: 5,
      headerComponent: () => (
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Typography
            sx={{
              color: "#71767f",
              fontFamily: "Inter, sans-serif",
              fontSize: "13px",
              fontWeight: "600",
              lineHeight: "18px",
            }}
          >
            Comments
          </Typography>
          {locked && (
            <Box sx={{ transform: "scale(0.7)" }}>
              <LockIcon />
            </Box>
          )}
        </Stack>
      ),
      headerName: "Manager Notes",
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ({ data }: any) => {
        return (
          <Chip
            label={conditionStatusToString(
              data.status,
              Boolean(data.markedAsNotApplicable)
            )}
            sx={{
              backgroundColor: conditionStatusToColor(
                data.status,
                Boolean(data.markedAsNotApplicable)
              ),
              color: "black",
              margin: "auto",
            }}
            size="small"
            variant="filled"
            className="chip"
          />
        );
      },
      cellStyle: centerCellStyles,
      field: "status",
      flex: 3,
      headerClass: "centered-table-header",
      headerName: "Status",
    },
    {
      autoHeight: true,
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[]);

  const buttons = [
    {
      action: async () => {
        setExportModalOpen(true);
      },
      icon: <ExportIcon />,
      text: "Download Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
    {
      action: async () => {
        setImportModalOpen(true);
      },
      icon: <ImportIcon />,
      text: "Import Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 160,
    },
    {
      action: async () => {
        handleEditCondition();
      },
      icon: <AddIcon variant="secondary" />,
      text: "Add Condition",
      type: HeaderButtonType.PRIMARY,
      width: 160,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Assigned",
      value: 1,
    },
    {
      label: "In Review",
      value: 2,
    },
    {
      label: "Approved",
      value: 3,
    },
  ];

  const filteredConditions = (() => {
    switch (tabIndex) {
      case 1:
        return conditions.filter(
          (condition) => condition.status === ConditionStatus.Assigned
        );
      case 2:
        return conditions.filter(
          (condition) => condition.status === ConditionStatus.InReview
        );
      case 3:
        return conditions.filter(
          (condition) => condition.status === ConditionStatus.Approved
        );
      case 4:
        return conditions.filter(
          (condition) => condition.status === ConditionStatus.NotApplicable
        );
      default:
        return conditions;
    }
  })();

  const baConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.BCA
  );
  const daConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.DA
  );

  const cellActions = [
    {
      action: handleOpenConditionDocumentModal,
      icon: <AddIcon />,
      title: "Add Documents",
    },
    {
      action: handleEditCondition,
      icon: <EditProjectIcon />,
      title: "Edit Condition",
    },
    {
      action: handleMarkAsNotApplicable,
      icon: <ReloadIcon />,
      title: "Toggle Not Applicable",
    },
    {
      action: handleDeleteCondition,
      icon: <ArchiveProjectIcon />,
      title: "Delete Condition",
    },
  ] as CellAction[];

  useEffect(() => {
    if (status) {
      setTabIndex(parseInt(status));
    }
  }, [status]);

  const deleteAllButton = [
    {
      action: async () => {
        if (selectedConditionsBCA.length || selectedConditionsDA.length) {
          handleDeleteAll();
        }
      },
      disabled:
        selectedConditionsBCA.length === 0 && selectedConditionsDA.length === 0,
      icon: <DeleteIcon />,
      text: "Delete Selected",
      type: HeaderButtonType.DANGER,
      width: 160,
    },
  ];

  return (
    <Grid item xs={12} md={12} border={"1px solid #e9eaeb"} borderRadius="12px">
      <Stack direction="column">
        <Header
          loading={loading}
          mainTitle={"Conditions"}
          isSubheading
          buttons={buttons}
          handleSearchChange={handleSearchChange}
          tabs={tabs}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
          searchTerm={searchTerm}
        />
        <Grid
          container
          sx={{
            borderBottom: "1px solid #e9eaeb",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <Header
            loading={loading}
            mainTitle={""}
            subTitle={"BA Conditions"}
            isSubheading
            paddingTop="12px"
            buttons={deleteAllButton}
          />
          <Grid item xs={12} md={12} mt={2}>
            <Table
              columnDefs={colDefs}
              loading={loading}
              data={baConditions}
              rowHeight={100}
              noFullTableBorder
              context={{
                allAreSelected:
                  baConditions.length &&
                  selectedConditionsBCA.length === baConditions.length,
                canEdit,
                cellActions,
                disabled,
                handleCheck,
                handleConditionSave,
                handleSelectAll: handleSelectAllBCA,
                locked,
                organisation,
                profile,
                selectedIds: selectedConditionsBCA.map((c) => c.id),
              }}
            />
          </Grid>
        </Grid>
        <Header
          loading={loading}
          mainTitle={""}
          subTitle={"DA Conditions"}
          isSubheading
          buttons={deleteAllButton}
        />
        <Grid item xs={12} md={12} mt={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={daConditions}
            noFullTableBorder
            context={{
              allAreSelected:
                daConditions.length &&
                selectedConditionsDA.length === daConditions.length,
              canEdit,
              cellActions,
              disabled,
              handleCheck,
              handleSelectAll: handleSelectAllDA,
              locked,
              organisation,
              profile,
              selectedIds: selectedConditionsDA.map((c) => c.id),
            }}
          />
        </Grid>
      </Stack>
      <Box sx={{ bottom: 16, position: "absolute", right: 16 }}>
        <StyledSpeedDial
          ariaLabel="SpeedDial playground example"
          onClick={() => setLocked(!locked)}
          open={!locked}
          icon={locked ? <LockIcon /> : <LockOpenIcon />}
          direction={"up"}
        />
      </Box>
      <Box sx={{ marginTop: "0" }}>
        {importModalOpen && project && permit ? (
          <ConditionImport
            project={project}
            permit={permit}
            bcaCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.BCA
              ).length
            }
            daCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.DA
              ).length
            }
            loading={false}
            open={importModalOpen}
            handleImportConditons={handleImportConditons}
            setOpen={handleOpenImportModel}
          />
        ) : null}
      </Box>
      {project && permit && exportModalOpen ? (
        <ConditionExport
          project={project}
          permit={permit}
          loading={false}
          open={exportModalOpen}
          setOpen={handleOpenExportModal}
          getPdfReport={getPdfReport}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}

      {selectedConditionsBCA.length || selectedConditionsDA.length ? (
        <ConfirmationDialog
          {...dialogState}
          onCancel={closeDialog}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Grid>
  );
};
