import {
  Box,
  Button,
  Menu,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon } from "assets/constants";
import dayjs from "dayjs";
import { useState } from "react";

interface DatePickerProps {
  loading: boolean;
  handleDateChange: (currentDate: Date) => void;
  currentDate: Date;
}
export const DatePicker = ({
  currentDate,
  handleDateChange,
  loading,
}: DatePickerProps) => {
  // State Management
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDateText, setSelectedDateText] = useState("Select Date");

  // Open/Close Dialog
  const handleClose = () => setAnchorEl(null);

  // Handle Apply Button
  const handleApply = () => {
    if (currentDate) {
      const formattedText = `${formatDate(currentDate)}}`;
      setSelectedDateText(formattedText);
      setAnchorEl(null);
    }
  };

  const formatDate = (date: string | Date): string => {
    const dateObject = new Date(date);
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date");
    }
    return dateObject.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <Button
          disableRipple
          onClick={handleToggleMenu}
          sx={{
            "&:disabled": {
              backgroundColor: "white",
              color: "#414651",
              cursor: "not-allowed",
              opacity: "60%",
            },
            "&:hover": {
              backgroundColor: "white",
            },
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid #D5D7DA",
            borderRadius: "8px",
            color: "#414651",
            display: "flex",
            padding: "8px 14px",
            textTransform: "none",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              mr: 1,
            }}
          >
            <CalendarIcon />
          </Box>
          <Typography
            variant="button"
            style={{ fontFamily: '"Inter", sans-serif' }}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              textTransform: "capitalize",
            }}
          >
            {selectedDateText}
          </Typography>
        </Button>
      </Skeleton>
    );
  }

  return (
    <Box>
      <Button
        disableRipple
        className="search-box"
        onClick={handleToggleMenu}
        sx={{
          "&:disabled": {
            backgroundColor: "white",
            color: "#414651",
            cursor: "not-allowed",
            opacity: "60%",
          },
          "&:hover": {
            backgroundColor: "white",
          },
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "8px",
          color: "#414651",
          display: "flex",
          padding: "8px 14px",
          textTransform: "none",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            mr: 1,
          }}
        >
          <CalendarIcon />
        </Box>
        <Typography
          variant="button"
          style={{ fontFamily: '"Inter", sans-serif' }}
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            textTransform: "capitalize",
          }}
        >
          {selectedDateText}
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            border: "1px solid #E9EAEB",
            borderRadius: "8px",
            boxShadow: "0px 1px 2px 0px #0A0D120D",
            marginTop: "4px",
            overflowY: "auto",
            padding: "24px",
            width: 350,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" justifyContent="space-between">
            <DateCalendar
              value={dayjs(currentDate)}
              onChange={(newValue) =>
                handleDateChange(newValue?.toDate() || new Date())
              }
            />
          </Box>
        </LocalizationProvider>

        {/* Date Display Fields */}
        <Box display="flex" justifyContent="space-between">
          <TextField
            label="Date"
            value={currentDate ? formatDate(currentDate) : ""}
            InputProps={{ readOnly: true }}
            fullWidth
            margin="normal"
          />
        </Box>

        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          variant="contained"
          color="primary"
          disabled={!currentDate}
        >
          Apply
        </Button>
      </Menu>
    </Box>
  );
};
