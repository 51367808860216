import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { MenuItem, TextField, Box, Grid, FormControl } from "@mui/material";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { Country, State } from "country-state-city";
import { useFormik } from "formik";
import isValidACN from "is-valid-acn";
import {
  OrganisationLineItem,
  PaymentPlan,
  createDefaultOrganisation,
  paymentPlanToString,
} from "permit-one-common/src/interfaces/organisation";
import { validateABN } from "permit-one-common/src/utils/abn";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import React, { useState } from "react";
import * as yup from "yup";

interface CreateOrganisationFormProps {
  loading: boolean;
  setLoading: (vlaue: boolean) => void;
  handleBack: () => void;
  finalizeAccount: (organisation: OrganisationLineItem) => void;
}

export const CreateOrganisationForm = ({
  finalizeAccount,
  handleBack,
  loading,
  setLoading,
}: CreateOrganisationFormProps) => {
  const [planType, setPlanType] = useState(PaymentPlan.Free);
  const [organisation] = useState<OrganisationLineItem>(
    createDefaultOrganisation()
  );

  const validationSchema = yup.object({
    abn: yup
      .string()
      .test(
        "Valid ABN/ACN",
        "Not a valid ABN/ACN - e.g. 53 004 085 616",
        function (item) {
          if (item && item.length > 0) {
            return validateABN(item) || isValidACN(item);
          }
          return true;
        }
      ),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    organisationName: yup.string().required("Organisation name is required"),
    postCode: yup
      .string()
      .required("Post code is required")
      .test(
        "Valid for country",
        "Not a valid post code for country",
        function (item) {
          if (postcodeValidatorExistsForCountry(this.parent.country)) {
            const valid = postcodeValidator(item, this.parent.country);
            return valid;
          }
          return true;
        }
      ),
    state: yup.string().required("State is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      abn: organisation.abn,
      city: organisation.city,
      country: organisation.country,
      organisationName: organisation.organisationName,
      postCode: organisation.postCode,
      state: organisation.state,
    },
    onSubmit: async (values) => {
      setLoading(true);
      await finalizeAccount({
        ...organisation,
        abn: values.abn,
        city: values.city,
        country: values.country,
        organisationName: values.organisationName,
        planType: planType,
        postCode: values.postCode,
        state: values.state,
      });
      setLoading(false);
    },
    validationSchema: validationSchema,
  });

  const handlePlanTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanType(event.target.value as PaymentPlan);
  };
  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        margin: "0",
        padding: "40px",
      }}
    >
      <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <TextField
              select
              label="Plan Type"
              fullWidth
              onChange={handlePlanTypeChange}
              sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
              value={planType}
            >
              {Object.values(PaymentPlan).map((p, index) => (
                <MenuItem key={`${p}-${index}`} value={p}>
                  {paymentPlanToString(p)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6}>
            <TextField
              id="organisationName"
              name="organisationName"
              label="Organisation Name"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.organisationName &&
                Boolean(formik.errors.organisationName)
              }
              helperText={
                formik.touched.organisationName
                  ? formik.errors.organisationName
                  : ""
              }
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="abn"
              name="abn"
              label="ABN/ACN"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.abn}
              error={formik.touched.abn && Boolean(formik.errors.abn)}
              helperText={formik.touched.abn ? formik.errors.abn : ""}
            />
          </Grid>

          <Grid item md={6}>
            <FormControl fullWidth>
              <TextField
                select
                id="country"
                name="country"
                disabled={formik.isSubmitting}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country ? formik.errors.country : ""}
                sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
                label="Country"
              >
                {countries.map((c) => (
                  <MenuItem key={c.isoCode} value={c.isoCode}>
                    {c.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <TextField
                select
                id="state"
                name="state"
                disabled={formik.isSubmitting}
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state ? formik.errors.state : ""}
                sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
                label="State"
              >
                {states.map((s, index) => (
                  <MenuItem key={`${s.isoCode}-${index}`} value={s.isoCode}>
                    {s.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <TextField
                id="city"
                name="city"
                margin="dense"
                fullWidth
                disabled={formik.isSubmitting}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city ? formik.errors.city : ""}
                label="City"
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <TextField
              id="postCode"
              name="postCode"
              label="Post Code"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postCode}
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode ? formik.errors.postCode : ""}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{
              display: "flex !important",
              justifyContent: "left !important;",
            }}
          >
            <StyledButton
              loading={false}
              btnType={ButtonType.Secondary}
              onClick={handleBack}
            >
              Back
            </StyledButton>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <LoadingButton
              color="primary"
              type="submit"
              loading={loading}
              disabled={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
