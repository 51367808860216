import { useDirectory } from "@hooks/crud/useDirectory";
import { Grid } from "@mui/material";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import { Header } from "../Header/Header";
import { CollaboratorTable2 } from "../Tables/CollaboratorTable/CollaboratorTable2";

interface PermitCollaboratorsProps {
  loading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  profile?: ProfileLineItem;
  handleToggleShareCondition: (directory: DirectoryContactLineItem) => void;
}
export const PermitCollaborators = ({
  handleToggleShareCondition,
  loading,
  permit,
  profile,
  project,
}: PermitCollaboratorsProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const { directory, isDirectoryLoading, setDirectory } = useDirectory(
    undefined,
    permit?.id
  );

  const handleToggleShareConditionWithDirectoryUpdate = async (
    directory: DirectoryContactLineItem
  ) => {
    await handleToggleShareCondition(directory);

    setDirectory((prevDirectory) =>
      prevDirectory.filter((d) => d.id !== directory.id)
    );
  };
  return (
    <Grid item xs={12} md={12}>
      <Header
        mainTitle="Permit Contacts"
        subTitle="&nbsp;"
        loading={loading || isDirectoryLoading}
        isSubheading
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
      />
      <Grid item xs={12} md={12}>
        <CollaboratorTable2
          loading={loading || isDirectoryLoading}
          directory={directory}
          project={project}
          profile={profile}
          searchTerm={searchTerm}
          handleToggleShareCondition={
            handleToggleShareConditionWithDirectoryUpdate
          }
        />
      </Grid>
    </Grid>
  );
};

interface PublicPermitCollaboratorsProps {
  loading: boolean;
  directory: DirectoryContactLineItem[];
}

export const PublicPermitCollaborators = ({
  directory,
  loading,
}: PublicPermitCollaboratorsProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  return (
    <Grid item xs={12} md={12}>
      <Header
        mainTitle="Permit Contacts"
        subTitle=" "
        loading={loading}
        isSubheading
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
      />
      <Grid
        item
        md={12}
        sx={{
          alignItems: "center",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          padding: "0",
        }}
      >
        <CollaboratorTable2
          loading={loading}
          directory={directory}
          searchTerm={searchTerm}
          readOnly
        />
      </Grid>
    </Grid>
  );
};
