import { Grid } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface UserTable2Props {
  loading: boolean;
  profile: ProfileLineItem;
  profiles: ProfileLineItem[];
  searchTerm: string;
  handleDeleteUser: (profile: ProfileLineItem) => void;
  handleEditUser: (profile: ProfileLineItem) => void;
}

export const UserTable2 = ({
  handleDeleteUser,
  handleEditUser,
  loading,
  profile,
  profiles,
  searchTerm,
}: UserTable2Props) => {
  const colDefs = [
    {
      field: "firstName",
      flex: 3,
      headerName: "First Name",
    },
    {
      field: "lastName",
      flex: 3,
      headerName: "Last Name",
    },
    {
      field: "email",
      flex: 3,
      headerName: "Email",
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "id",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Role",
      valueFormatter: (params: any) => {
        return params.data.isAdmin ? "Admin" : "User";
      },
    },
    {
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  const cellActions = [
    {
      action: handleEditUser,
      icon: <EditProjectIcon />,
      title: "Edit User",
      validateEnabled: (data: ProfileLineItem) => data.id !== profile?.id,
    },
    {
      action: handleDeleteUser,
      icon: <ArchiveProjectIcon />,
      title: "Delete User",
      validateEnabled: (data: ProfileLineItem) => data.id !== profile?.id,
    },
  ] as CellAction[];

  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={profiles}
        context={{
          cellActions,
          profile,
        }}
        searchValue={searchTerm}
        searchKeys={["firstName", "lastName", "email"]}
      />
    </Grid>
  );
};
