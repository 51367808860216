import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography, IconButton, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { ChangeEvent, useState } from "react";

export interface RejectDialogProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: (rejectionMessage: string) => void;
}

export const RejectDialog = ({
  onCancel,
  onConfirm,
  open,
  title,
}: RejectDialogProps) => {
  const [rejectComment, setRejectComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "25px 30px 20px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            "&:hover": {
              background: "#023AF3",
            },
            background: "#000",
            color: "#fff",
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 30px 30px" }}>
        <Typography
          sx={{ background: "#fff", borderRadius: "10px", padding: "10px" }}
        >
          Please provide comments on rejecting this condition.
        </Typography>

        <TextField
          fullWidth
          id="outlined-multiline-flexible"
          label="Reason"
          multiline
          rows={3}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setRejectComment(e.target.value);
          }}
          maxRows={4}
        />
      </DialogContent>
      <DialogActions sx={{ padding: "0 30px 30px" }}>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            loading={false}
            btnType={ButtonType.Error}
            disabled={rejectComment.length === 0 || isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              onConfirm(rejectComment);
            }}
          >
            Reject
          </StyledButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
