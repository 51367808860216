import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProject } from "@hooks/crud/useProject";
import { AddIcon } from "@stories/atoms/Icon/Icons";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import ProjectModal from "@stories/organisms/ProjectModal/ProjectModal";
import { ProjectTable } from "@stories/organisms/Tables/ProjectTable/ProjectTable";
import * as RouteHelper from "@utils/routes";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ListProjects = (): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();
  const [open, setOpen] = useState(false);

  const [selectedProject, setSelectedProject] = useState<
    ProjectLineItem | undefined
  >(undefined);

  const { createProject, isProjectLoading, projects, updateProject } =
    useProject(userProfile);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProject(undefined);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditProject = useCallback(
    (project: ProjectLineItem) => {
      setSelectedProject(project);
      handleOpen();
    },
    [selectedProject]
  );

  const handleViewProject = useCallback((project: ProjectLineItem) => {
    navigate(RouteHelper.overview(project.id));
  }, []);

  const handleArchiveProject = useCallback(
    async (project: ProjectLineItem) => {
      updateProject({ ...project, archived: !project.archived });
    },
    [projects]
  );

  const loading = isProjectLoading || isAuthProfileLoading;

  const buttons = [
    {
      action: handleOpen,
      icon: <AddIcon variant="secondary" />,
      text: "Add Project",
      type: HeaderButtonType.PRIMARY,
      width: 130,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "Active",
    },
    {
      label: "Archived",
    },
  ];

  const filteredProejcts = (() => {
    switch (tabIndex) {
      case 1:
        return projects.filter((project) => project.archived);
      default:
        return projects.filter((project) => !project.archived);
    }
  })();
  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "SocialPro Projects"}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Projects"}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <ProjectTable
        projects={filteredProejcts}
        organisation={userOrganisation}
        profile={userProfile}
        loading={loading}
        handleOpen={handleOpen}
        handleViewProject={handleViewProject}
        handleEditProject={handleEditProject}
        handleArchiveProject={handleArchiveProject}
        searchString={searchTerm}
        tabNumber={tabIndex}
      />
      {userOrganisation && open ? (
        <ProjectModal
          open={open}
          organisation={userOrganisation}
          handleClose={handleClose}
          createProject={createProject}
          updateProject={updateProject}
          existingProject={selectedProject}
        />
      ) : null}
    </PageContainer>
  );
};
