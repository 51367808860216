import { UploadStatus } from "@hooks/utils/useUpload";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";

export interface UploadModalProps {
  uploadStatus: UploadStatus;
  total: number;
}

export const UploadModal = ({ total, uploadStatus }: UploadModalProps) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={uploadStatus !== UploadStatus.Idle}
    >
      <DialogTitle>Uploading Documents</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Box sx={{ margin: "auto !important" }}>
              {total === 0 ? (
                <CircularProgress />
              ) : (
                <CircularProgressWithLabel value={total} />
              )}
            </Box>
            {uploadStatus === UploadStatus.Uploading ? (
              <Typography>{`Uploading`}</Typography>
            ) : (
              <Typography>{`Saving...`}</Typography>
            )}
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
