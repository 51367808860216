import { InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormatCustom } from "@stories/atoms/NumericalFormatCustom/NumericalFormatCustom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { ChangeEvent } from "react";
import * as yup from "yup";

interface ProjectDetailsFormProps {
  project: ProjectLineItem;
  handleSubmit: (profile: ProjectLineItem) => void;
}

export const ProjectDetailsForm = ({
  handleSubmit,
  project,
}: ProjectDetailsFormProps) => {
  const validationSchema = yup.object({
    completionDate: yup.date(),
    contractor: yup.string(),
    estimatedProjectCost: yup
      .number()
      .required("Estimated project cost is required")
      .moreThan(0, "Estimated project cost must be greater than 0"),
    projectCode: yup.string().required("Project code is required"),
    projectDescription: yup
      .string()
      .required("Project description is required"),
    projectName: yup.string().required("Project name is required"),
    projectSector: yup.string().required("Project sector is required"),
    projectSectorOther: yup
      .string()
      .test(
        "Required if other",
        "Sector description required",
        function (item) {
          if (this.parent.projectSector === ProjectSector.Other) {
            if (!item) {
              return false;
            }
            if (item.length === 0) {
              return false;
            }
          }
          return true;
        }
      ),
    startDate: yup.date(),
  });

  const formik = useFormik({
    initialValues: {
      completionDate: project.completionDate
        ? dayjs(project.completionDate)
        : undefined,
      contractor: project.contractor,
      developer: project.developer,
      estimatedProjectCost: project.estimatedProjectCost,
      managers: [],
      projectCode: project.projectCode,
      projectDescription: project.projectDescription,
      projectName: project.projectName,
      projectSector: project.projectSector,
      projectSectorOther: project.projectSectorOther,
      startDate: project.startDate ? dayjs(project.startDate) : undefined,
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...project,
        completionDate: values.completionDate
          ? values.completionDate.toDate()
          : undefined,
        contractor: values.contractor,
        developer: values.developer,
        estimatedProjectCost: values.estimatedProjectCost,
        projectCode: values.projectCode,
        projectDescription: values.projectDescription,
        projectName: values.projectName,
        projectSector: values.projectSector,
        projectSectorOther: values.projectSectorOther,
        startDate: values.startDate ? values.startDate.toDate() : undefined,
      });
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            id="projectName"
            name="projectName"
            label="Project Name"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const projectName = event.currentTarget.value as string;
              formik.setFieldValue(
                "projectCode",
                projectName.substring(0, 3).toUpperCase()
              );
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.projectName}
            error={
              formik.touched.projectName && Boolean(formik.errors.projectName)
            }
            helperText={
              formik.touched.projectName ? formik.errors.projectName : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="projectCode"
            name="projectCode"
            label="Project Code"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.projectCode}
            error={
              formik.touched.projectCode && Boolean(formik.errors.projectCode)
            }
            helperText={
              formik.touched.projectCode ? formik.errors.projectCode : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="projectDescription"
            name="projectDescription"
            label="Project Description"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.projectDescription}
            error={
              formik.touched.projectDescription &&
              Boolean(formik.errors.projectDescription)
            }
            helperText={
              formik.touched.projectDescription
                ? formik.errors.projectDescription
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="estimatedProjectCost"
            name="estimatedProjectCost"
            label="Estimated Project Cost"
            fullWidth
            margin="dense"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.estimatedProjectCost}
            error={
              formik.touched.estimatedProjectCost &&
              Boolean(formik.errors.estimatedProjectCost)
            }
            helperText={
              formik.touched.estimatedProjectCost
                ? formik.errors.estimatedProjectCost
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            select
            id="projectSector"
            name="projectSector"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            value={formik.values.projectSector}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.projectSector &&
              Boolean(formik.errors.projectSector)
            }
            sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
            helperText={
              formik.touched.projectSector ? formik.errors.projectSector : ""
            }
            label="Project Sector"
          >
            {Object.values(ProjectSector).map((p, index) => (
              <MenuItem key={`project-sector-${index}`} value={p}>
                {projectSectorToString(p)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="projectSectorOther"
            name="projectSectorOther"
            label="Other"
            fullWidth
            margin="dense"
            disabled={
              formik.isSubmitting ||
              formik.values.projectSector !== ProjectSector.Other
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.projectSectorOther}
            error={
              formik.touched.projectSectorOther &&
              Boolean(formik.errors.projectSectorOther)
            }
            helperText={
              formik.touched.projectSectorOther
                ? formik.errors.projectSectorOther
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="developer"
            name="developer"
            label="Developer"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.developer}
            error={formik.touched.developer && Boolean(formik.errors.developer)}
            helperText={formik.touched.developer ? formik.errors.developer : ""}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="contractor"
            name="contractor"
            label="Contractor"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contractor}
            error={
              formik.touched.contractor && Boolean(formik.errors.contractor)
            }
            helperText={
              formik.touched.contractor ? formik.errors.contractor : ""
            }
          />
        </Grid>
        <Grid item md={6} className="fullwidth">
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("startDate", value, true);
              }
            }}
            format={"DD/MM/YYYY"}
            disabled={formik.isSubmitting}
            value={formik.values.startDate}
            slotProps={{
              textField: {
                error:
                  formik.touched.startDate && Boolean(formik.errors.startDate),
                helperText:
                  formik.touched.startDate && formik.errors.startDate
                    ? "Invalid Date"
                    : "",
                label: "Start Date",
              },
            }}
          />
        </Grid>
        <Grid item md={6} className="fullwidth">
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("completionDate", value, true);
              }
            }}
            format={"DD/MM/YYYY"}
            disabled={formik.isSubmitting}
            value={formik.values.completionDate}
            slotProps={{
              textField: {
                error:
                  formik.touched.completionDate &&
                  Boolean(formik.errors.completionDate),
                helperText:
                  formik.touched.completionDate && formik.errors.completionDate
                    ? "Invalid Date"
                    : "",
                label: "Target Completion Date",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          justifyContent: "right !important;",
          padding: "10px 0 0",
        }}
      >
        <StyledButton loading={false} type="submit">
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
